import { useAppDispatch } from '@store/hook'
import { bubblesActions } from '@store/slice/bubbles'
import { userActions } from '@store/slice/user'
import { useCallback } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

export const useProcessUserSocket = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const processUser = (message: any) => {
    const data = message.data

    dispatch(userActions.setUser(data))
    dispatch(
      bubblesActions.setData({
        ...data.coins,
        maxEnergy: data.maxEnergy,
        energy: data.energy,
        level: data.level,
      }),
    )
  }

  const processRestoreEnergy = (message: any) => {
    dispatch(userActions.setEnergy(message.energy))
  }

  const processUnlockInvitedHouse = (message: any) => {
    const { coins, ...rest } = message.result
    dispatch(userActions.setHouses(rest))
    dispatch(bubblesActions.setCoins(coins))
  }

  const processRestoreTurboBoost = (message: any) => {
    dispatch(userActions.restoreTurboBoost(message.data))
  }

  const processRestoreEnergyRecoveryBoost = (message: any) => {
    dispatch(userActions.restoreEnergyRecoveryBoost(message.data))
  }

  const processTransactionSuccess = (message: any) => {
    switch (message.type) {
      case 'BUY_ENERGY_RECOVERY_BOOST':
        toast.success(t('Успешно куплено восстановление энергии'))
        dispatch(userActions.purchasedEnergyRecoveryBoost(message.data))
        break
      case 'BUY_TURBO_BOOST':
        toast.success(t('Успешно куплен трубо буст'))
        dispatch(userActions.purchasedTurboBoost(message.data))
        break
      case 'BUY_HOUSE':
        toast.success(t(`Успешно куплен дом ${message.house}`))
        dispatch(userActions.setHouses(message.data))
        break
      case 'BUY_TAP_BOT_BOOST':
        toast.success(t('Успешно куплен буст тап бот'))
        dispatch(userActions.purchasedTapBot())
        break
      case 'BUY_LIMIT_ENERGY_BOOST':
        toast.success(t('Успешно куплен буст увеличение энергии'))
        dispatch(userActions.purchasedLimitEnergy(message.data))
    }
  }

  const processTransactionError = (message: any) => {
    switch (message.type) {
      case 'BUY_ENERGY_RECOVERY_BOOST':
        toast.success(t('Не удалось купить восстановление энергии буст'))
        break
      case 'BUY_TURBO_BOOST':
        toast.success(t('Не удалось купить трубо буст'))
        break
      case 'BUY_HOUSE':
        toast.success(t('Не удалось купить дом'))
        break
      case 'BUY_TAP_BOT_BOOST':
        toast.success(t('Не удалось купить тап бот буст'))
        break
      case 'BUY_LIMIT_ENERGY_BOOST':
        toast.success(t('Не удалось купить увеличение энергии буст'))
    }
  }

  const processMessage = useCallback((message: any) => {
    switch (message.action) {
      case 'user':
        processUser(message)
        break
      case 'restore_energy':
        processRestoreEnergy(message)
        break
      case 'unlock_invited_house':
        processUnlockInvitedHouse(message)
        break
      case 'restore_daily_boosts_turbo':
        processRestoreTurboBoost(message)
        break
      case 'restore_daily_boosts_full_energy':
        processRestoreEnergyRecoveryBoost(message)
        break
      case 'transaction_success':
        processTransactionSuccess(message)
        break
      case 'transaction_error':
        processTransactionError(message)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return processMessage
}
