import { BottomDrawer } from '@parts/BottomDraver'
import { SectionItem } from '@parts/SectionItem/SectionItem'
import { useAppSelector } from '@store/hook'
import { CLAIM_ENERGY } from '@utils/constants'
import { useToggle } from 'hook/useToggle'
import { useTranslation } from 'react-i18next'
import { ScheduleDrawerInner } from './ScheduleDrawerInner'

export const Schedule = () => {
  const { t } = useTranslation()
  const [isOpen, toggleIsOpen] = useToggle()

  const claimedEnergyDay = useAppSelector(
    state => state.userReducer.user!.claimedEnergyDay,
  )

  const lastTimeClaimedEnergy = useAppSelector(
    state => state.userReducer.user!.lastTimeClaimedEnergy,
  )

  const todayClaimEnergy = CLAIM_ENERGY.find(
    item => item.day === claimedEnergyDay,
  )

  const isCompleted =
    new Date(lastTimeClaimedEnergy).getDate() === new Date().getDate()

  return (
    <>
      <SectionItem
        onClick={toggleIsOpen}
        description={`+${todayClaimEnergy?.energy} ${t('энергии')}`}
        icon="/assets/img/schedule/schedule_small.png"
        isCompleted={isCompleted}
        title={t('Ежедневная Энергия')}
        positionInList="first"
      />
      <BottomDrawer isOpen={isOpen} onClose={toggleIsOpen}>
        <ScheduleDrawerInner />
      </BottomDrawer>
    </>
  )
}
