import { useAppDispatch, useAppSelector } from '@store/hook'
import { bubblesActions } from '@store/slice/bubbles'
import { useCallback, useEffect } from 'react'
import { useSocketContext } from './useSocketContext'

export const useSendClicks = () => {
  const { sendJsonMessage } = useSocketContext()
  const lastClickedTime = useAppSelector(
    state => state.bubblesReducer.lastClickedTime,
  )
  const clicks = useAppSelector(state => state.bubblesReducer.clicks)
  const sendedClicks = useAppSelector(
    state => state.bubblesReducer.sendedClicks,
  )
  const isBlocked = useAppSelector(state => state.userReducer.user!.isBlocked)

  const dispatch = useAppDispatch()

  const sendClicks = useCallback(() => {
    if (isBlocked || !lastClickedTime) {
      return
    }

    const clickedTime = new Date(lastClickedTime)
    clickedTime.setSeconds(clickedTime.getSeconds() + 1)
    const isGoSend = clickedTime < new Date()

    if (!isGoSend) {
      return
    }

    const clicksToSend = clicks - sendedClicks
    if (clicksToSend > 0) {
      sendJsonMessage({
        action: 'tap',
        data: clicksToSend,
      })
      dispatch(bubblesActions.setSendedClicks(clicks))
    }
  }, [lastClickedTime, clicks, sendedClicks, isBlocked])

  useEffect(() => {
    const interval = setInterval(sendClicks, 1000)

    Telegram.WebApp.onEvent('backButtonClicked', sendClicks)
    // @ts-expect-error
    Telegram.WebView.onEvent('web_app_close', sendClicks)

    // @ts-expect-error
    Telegram.WebView.onEvent('web_app_setup_closing_behavior', sendClicks)

    return () => {
      clearInterval(interval)
    }
  }, [sendClicks])

  return {
    sendClicks,
  }
}
