import { SectionItem } from '@parts/SectionItem/SectionItem'
import { useToggle } from 'hook/useToggle'
import { BoostDrawer } from '../BoostDrawer'
import { useTurboActive } from './useTurboActive'
import { useTurboBuy } from './useTurboBuy'

export const Turbo = () => {
  const [isOpen, toggleIsOpen] = useToggle()
  const {
    description,
    isChecked,
    disabledActiveButton,
    isLoadingOnActive,
    handleTurbo,
    buttonText,
  } = useTurboActive()

  const { handleBuy, isLoadingOnBuy } = useTurboBuy()

  return (
    <>
      <SectionItem
        onClick={toggleIsOpen}
        description={description}
        icon="/assets/img/boost/rocket.png"
        isLoading={isLoadingOnActive || isLoadingOnBuy}
        title={'Турбо х3'}
      />
      <BoostDrawer
        boostType="TURBO"
        isOpen={isOpen}
        isChecked={isChecked}
        close={toggleIsOpen}
        handleSwitcher={handleTurbo}
        disabledActiveButton={disabledActiveButton}
        handleBuy={async () => await handleBuy(0.1).then(toggleIsOpen)}
        isLoadingOnBuy={isLoadingOnBuy}
        isLoadingOnActive={isLoadingOnActive}
        buttonText={buttonText}
      />
    </>
  )
}
