import { useSubscriptionToChatMutation } from '@api/endpoints/game'
import { SectionItem } from '@parts/SectionItem/SectionItem'
import { useAppSelector } from '@store/hook'
import { CHATS_IDS } from '@utils/constants'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

export const SubscribedToChat = () => {
  const { t } = useTranslation()

  const language = useAppSelector(state => state.userReducer.user!.language)

  const subscribedToChatDate = useAppSelector(
    state => state.userReducer.user!.subscribedToChatDate,
  )

  const [subscriptionToChat, { isLoading }] = useSubscriptionToChatMutation()

  const redirectToChat = () => {
    const url = language === 'ru' ? CHATS_IDS.ru : CHATS_IDS.en
    Telegram.WebApp.openTelegramLink(url)
  }

  const handleSubscribe = async () => {
    if (isLoading) {
      return
    }

    try {
      await subscriptionToChat()
        .unwrap()
        .then(() => {
          toast.success(t('Вы подписаны на чат'))
        })
    } catch (error) {
      redirectToChat()
    }
  }

  return (
    <SectionItem
      description="+2000 PEPE"
      icon="/assets/img/common_icons/telegram.png"
      isCompleted={!!subscribedToChatDate}
      isLoading={isLoading}
      title={t('Подпишись на чат')}
      positionInList="other"
      descriptionIcon="/assets/img/coins/PEPE.svg"
      onClick={subscribedToChatDate ? () => {} : handleSubscribe}
    />
  )
}
