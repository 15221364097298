import { FullScreenLoader } from '@parts/FullScreenLoader/FullScreenLoader'
import { useAppDispatch, useAppSelector } from '@store/hook'
import { userActions } from '@store/slice/user'
import { useProcessUserSocket } from 'hook/useProcessSocket'
import i18n from 'i18n'
import { createContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useWebSocket from 'react-use-websocket'
import {
  type SendJsonMessage,
  type WebSocketLike,
} from 'react-use-websocket/dist/lib/types'

interface ISocketContext {
  sendJsonMessage: SendJsonMessage
}

export const SocketContext = createContext<ISocketContext | null>(null)

interface Props {
  children: React.ReactNode
}

const socketUrl = `wss://${
  new URL(process.env.REACT_APP_URL_API ?? 'https://swagerbyfalio.com/mem/v1')
    .host
}/mem/ws`

export const processReconnect = (
  event: WebSocketEventMap['close'],
  socket: WebSocketLike | null,
  token: boolean,
  logout: () => void,
) => {
  if (!token) return false
  if (event.code === 1006) return true
  if (event.code === 4401) {
    if (event.reason === 'UNAUTHORIZED') {
      console.log('Unauthorized')
      logout()
    }

    socket?.close()
  }
  if (event.reason === 'User is not subscribed') {
    Telegram.WebApp.close()
    return false
  } else if (event.reason === 'User not found') {
    // Telegram.WebApp.close()
    Telegram.WebApp.showAlert(
      i18n.t('Пользователь не найден, напишите в боте /start'),
      () => {
        Telegram.WebApp.close()
      },
    )
    return false
  }
  return false
}

export const SocketProvider = ({ children }: Props) => {
  const processingUserMessage = useProcessUserSocket()
  const [token, setToken] = useState<string | null | undefined>(undefined)
  const user = useAppSelector(state => state.userReducer.user)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    const telegramId = Telegram.WebApp.initDataUnsafe?.user?.id
    if (telegramId) {
      setToken(String(telegramId))
    } else {
      setToken(null)
    }
  }, [])

  const logout = () => {
    dispatch(userActions.logout())
    setToken(undefined)
  }

  const { getWebSocket, sendJsonMessage } = useWebSocket(
    socketUrl,
    {
      protocols: token ? [token] : undefined,
      shouldReconnect: (event: WebSocketEventMap['close']): boolean =>
        processReconnect(event, getWebSocket(), !!token, logout),
      reconnectInterval: 1000,
      onMessage: event => {
        try {
          const message = JSON.parse(event.data)
          processingUserMessage(message)
        } catch (error) {
          console.log('event.data', event.data)
          console.log('ws.onmessage error', error)
          // Sentry.captureMessage(
          //   // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          //   `Fail parsing socket data main_bf:${event.data as unknown}`,
          //   'warning',
          // )
        }
      },
    },
    !!token,
  )

  if (token === null) {
    return <p className="center_title">{t('Available only in Telegram')}</p>
  }

  if (!user) {
    return <FullScreenLoader />
  }

  return (
    <SocketContext.Provider value={{ sendJsonMessage }}>
      {children}
    </SocketContext.Provider>
  )
}
