import { BottomDrawer } from '@parts/BottomDraver'
import { Button } from '@parts/Button'
import { useTranslation } from 'react-i18next'
import styles from './Tasks.module.scss'

interface IThanks {
  isOpen: boolean
  onClose: () => void
}

export const Thanks = ({ isOpen, onClose }: IThanks) => {
  const { t } = useTranslation()

  return (
    <BottomDrawer isOpen={isOpen} onClose={onClose}>
      <div className={styles.thanks}>
        <img
          className={styles.thanks_icon}
          src="/assets/img/settings/thanks_bg.png"
          width={147}
          height={110}
          alt="decoration"
        />

        <h4 className="drawer_title">
          {t('Thank you for completing the task!')}
        </h4>
        <p className="drawer_description">
          {t(
            'It is currently under review, which will take from 1 to 6 hours. Once successfully verified, tokens will be credited to your balance.',
          )}
        </p>
        <Button onClick={onClose} styleType="blue">
          {t('Thanks')}
        </Button>
      </div>
    </BottomDrawer>
  )
}
