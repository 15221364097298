import clsx from 'clsx'
import styles from './BottomDrawer.module.scss'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

interface IBottomDrawer {
  isOpen: boolean
  onClose: () => void
  children: JSX.Element
}

const element = document.getElementById('portal')

export const BottomDrawer = ({ isOpen, children, onClose }: IBottomDrawer) => {
  const [active, setActive] = useState(false)
  const [show, setShow] = useState(false)

  useEffect(() => {
    let showTimeOut: null | NodeJS.Timeout = null
    let activeTimeOut: null | NodeJS.Timeout = null

    if (isOpen) {
      setActive(true)
      showTimeOut = setTimeout(() => {
        setShow(true)
      }, 10)
    } else {
      activeTimeOut = setTimeout(() => {
        setActive(false)
      }, 300)
      setShow(false)
    }

    return () => {
      if (showTimeOut) clearTimeout(showTimeOut)
      if (activeTimeOut) clearTimeout(activeTimeOut)
    }
  }, [isOpen])

  if (active && element)
    return createPortal(
      <div className={clsx(styles.drawer, { [styles.active]: show })}>
        <div onClick={onClose} className={styles.drawer_overlay} />
        <div onClick={onClose}></div>
        <div className={styles.drawer_child}>
          <div className={styles.drawer_close}>
            <img
              onClick={onClose}
              src="/assets/img/common_icons/close.svg"
              alt="close"
            />
          </div>
          {children}
        </div>
      </div>,
      element,
    )

  return null
}
