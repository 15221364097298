import { SectionItem } from '@parts/SectionItem/SectionItem'
import { useToggle } from 'hook/useToggle'
import { BoostDrawer } from '../BoostDrawer'
import { useEnergyRecoveryActive } from './useEnergyRecoveryActive'
import { useEnergyRecoveryBuy } from './useEnergyRecoveryBuy'
import { t } from 'i18next'

export const EnergyRecovery = () => {
  const [isOpen, toggleIsOpen] = useToggle()

  const {
    handleEnergyRecovery,
    isLoadingOnActive,
    isChecked,
    disabledActiveButton,
    buttonText,
  } = useEnergyRecoveryActive()

  const { handleBuy, isLoadingOnBuy } = useEnergyRecoveryBuy()

  return (
    <>
      <SectionItem
        onClick={toggleIsOpen}
        description={t('за 0.1')}
        icon="/assets/img/boost/speed.png"
        isLoading={isLoadingOnActive}
        title={t('Восстановление энергии')}
        descriptionIcon="/assets/img/coins/TON.svg"
      />
      <BoostDrawer
        boostType="FULL_ENERGY"
        isOpen={isOpen}
        isChecked={isChecked}
        close={toggleIsOpen}
        handleSwitcher={handleEnergyRecovery}
        handleBuy={async () => await handleBuy(0.1).then(toggleIsOpen)}
        disabledActiveButton={disabledActiveButton}
        isLoadingOnActive={isLoadingOnActive}
        isLoadingOnBuy={isLoadingOnBuy}
        buttonText={buttonText}
      />
    </>
  )
}
