export const formatNumbers = (value: number): string => {
  if (value < 1000) {
    return value.toString()
  } else if (value < 1000000) {
    const formatted = value / 1000
    return Number.isInteger(formatted)
      ? formatted.toString() + 'K'
      : formatted.toFixed(1) + 'K'
  } else {
    const formatted = value / 1000000
    return Number.isInteger(formatted)
      ? formatted.toString() + 'M'
      : formatted.toFixed(1) + 'M'
  }
}
