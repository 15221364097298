/* eslint-disable @typescript-eslint/promise-function-async */
import { Boost } from '@pages/boost'
import { Leaders } from '@pages/leaders'
import { Main } from '@pages/main'
import { PreMarket } from '@pages/preMarket'
import { RedirectToGeneralRout } from '@pages/redirect/RedirectToGeneralRout'
import { Settings } from '@pages/settings'
import { FullScreenLoader } from '@parts/FullScreenLoader/FullScreenLoader'
import { lazyRetry } from '@utils/lazyRetry'
import {
  getItemFromLocalStorage,
  setItemToLocalStorage,
} from '@utils/localStorageService'
import { ROUTES } from '@utils/routes'
import { useSendClicks } from 'hook/useSendClicks'
import i18n from 'i18n'
import { Suspense, lazy, memo, useEffect } from 'react'
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'

const Layout = lazy(() =>
  lazyRetry(() => import('components/layout'), 'Layout'),
)

const RoutesWrapper = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { sendClicks } = useSendClicks()

  useEffect(() => {
    const init = () => {
      Telegram.WebApp.ready()
      Telegram.WebApp.BackButton.show()
      Telegram.WebApp.BackButton.onClick(() => {
        if (window.location.pathname === '/') {
          sendClicks()
          Telegram.WebApp.close()
        } else {
          navigate(-1)
        }
      })
    }

    init()
  }, [])

  useEffect(() => {
    const checkLang = async () => {
      try {
        let lang =
          searchParams.get('lang') ?? getItemFromLocalStorage('lang') ?? 'en'

        if (i18n.options.resources?.[lang] === undefined) {
          lang = 'en'
        }

        await i18n.changeLanguage(lang)
        setItemToLocalStorage('lang', lang)
        document.documentElement.lang = lang
        searchParams.delete('lang', lang)
        setSearchParams(searchParams)
      } catch (error) {
        console.log('Error in checkLang', error)
      }
    }

    void checkLang()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense fallback={<FullScreenLoader />}>
      <Routes>
        <Route path={ROUTES.MAIN} element={<Layout />}>
          <Route index element={<Main />} />
          <Route path={ROUTES.SETTINGS} element={<Settings />} />
          <Route path={ROUTES.BOOST} element={<Boost />} />
          <Route path={ROUTES.LEADERS} element={<Leaders />} />
          <Route path={ROUTES.PRE_MARKET} element={<PreMarket />} />
          <Route path={ROUTES.ALL} element={<RedirectToGeneralRout />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

export default memo(RoutesWrapper)
