import { CoinsCounter } from './CoinsCounter'
import styles from './components.module.scss'
import { EnergyBar } from './EnergyBar'
import { PagesNavigation } from './PagesNavigation'

export const Header = () => {
  return (
    <div className={styles.header}>
      <div>
        <EnergyBar />
        <CoinsCounter />
      </div>
      <PagesNavigation />
    </div>
  )
}
