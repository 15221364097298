import { SectionItem } from '@parts/SectionItem/SectionItem'
import { useTranslation } from 'react-i18next'

export const SubscribeToTwitter = () => {
  const { t } = useTranslation()

  return (
    <SectionItem
      description={t('Бонус +1000 PEPE')}
      icon="/assets/img/common_icons/twitter.png"
      isCompleted={false}
      isLoading={false}
      title={t('Подписаться на Twitter(X)')}
      positionInList="other"
      descriptionIcon="/assets/img/coins/PEPE.svg"
    />
  )
}
