import { useLimitEnergyMutation } from '@api/endpoints/game'
import { useAppSelector } from '@store/hook'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

export const useLimitEnergyActive = () => {
  const { t } = useTranslation()
  const [limitEnergy, { isLoading }] = useLimitEnergyMutation()

  const isBuyLimitEnergyBoost = useAppSelector(
    state => state.userReducer.user!.isBuyLimitEnergyBoost,
  )
  const limitEnergyBoost = useAppSelector(
    state => state.userReducer.user!.limitEnergyBoost,
  )

  const handleLimitEnergy = async () => {
    if (isLoading) {
      toast.error(t('Loading...'))
      return
    }

    if (!isBuyLimitEnergyBoost) {
      toast.error(t('Буст не куплен'))
      return
    }

    toast.success(t(`Boost ${limitEnergyBoost ? 'deactivated' : 'activated'}`))

    try {
      await limitEnergy().unwrap()
    } catch (error) {
      console.log('Limit energy boost error', error)
    }
  }

  return {
    handleLimitEnergy,
    isLoadingOnActive: isLoading,
  }
}
