import { SetUserLvl } from '@parts/SetUserLvl'
import styles from './Main.module.scss'
import { Footer, Header } from './components'
import { BubbleContainer } from './components/BubbleContainer'
import { ClickButton } from './components/ClickButton'
import { Timer } from './components/Timer'

export const Main = () => {
  return (
    <div className={styles.body}>
      <BubbleContainer />
      <Header />
      <Timer />
      <ClickButton />
      <Footer />
      <SetUserLvl />
    </div>
  )
}
