import { COIN, type ICoinKey } from '@enums/coin'
import { type IGradeKey } from '@enums/grade'
import { GRADE_VALUE } from '@enums/gradeValue'
import { useAppSelector } from '@store/hook'
import { useLayoutEffect, useRef } from 'react'
import { Card } from './Card'
import styles from './components.module.scss'

export interface ICardData {
  icon: string
  coin: ICoinKey
  value: IGradeKey
}

const cards = [
  {
    id: COIN.PEPE,
    icon: '/assets/img/coins/PEPE.svg',
    coin: COIN.PEPE,
    value: GRADE_VALUE.COMMON,
  },
  {
    id: COIN.BONK,
    icon: '/assets/img/coins/BONK.svg',
    coin: COIN.BONK,
    value: GRADE_VALUE.COMMON,
  },
  {
    id: COIN.WIF,
    icon: '/assets/img/coins/WIF.svg',
    coin: COIN.WIF,
    value: GRADE_VALUE.COMMON,
  },
  {
    id: COIN.SHIB,
    icon: '/assets/img/coins/SHIB.svg',
    coin: COIN.SHIB,
    value: GRADE_VALUE.COMMON,
  },
  {
    id: COIN.DOGE,
    icon: '/assets/img/coins/DOGE.svg',
    coin: COIN.DOGE,
    value: GRADE_VALUE.COMMON,
  },
]

export const Footer = () => {
  const cardsRef = useRef<HTMLDivElement>(null)
  const houses = useAppSelector(state => state.userReducer.user?.houses) ?? []

  useLayoutEffect(() => {
    const cards = cardsRef.current
    if (cards) {
      const containerWidth = cards.offsetWidth
      const scrollAmount = (cards.scrollWidth - containerWidth) / 2
      cards.scrollLeft = scrollAmount
    }
  }, [])

  const allCards = cards.map(card => {
    const purchasedHouse = houses.find(h => h.name === card.coin)

    if (!purchasedHouse) {
      return card
    }

    return {
      id: purchasedHouse.name,
      icon: `/assets/img/coins/${purchasedHouse.name}.svg`,
      coin: purchasedHouse.name,
      value: purchasedHouse.type,
    }
  })

  return (
    <footer className={styles.footer}>
      <div ref={cardsRef} className={styles.footer_cards}>
        {allCards.map((house: any) => (
          <Card
            key={house.id}
            icon={house.icon}
            coin={house.coin}
            value={house.value}
          />
        ))}
      </div>
    </footer>
  )
}
