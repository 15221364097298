import { useEffect, type RefObject } from 'react'

export const useClickAwayListener = <T extends HTMLElement>(
  ref: RefObject<T>,
  handleClickAway: (event: MouseEvent) => void,
  avoidItemId?: string,
) => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const isAvoidElement = avoidItemId
        ? !!document.getElementById(avoidItemId)?.contains(event.target as Node)
        : true
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !isAvoidElement
      ) {
        handleClickAway(event)
      }
    }

    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, handleClickAway])
}
