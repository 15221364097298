import { GRADE } from '@enums/grade'
import { useAppDispatch, useAppSelector } from '@store/hook'
import { bubblesActions } from '@store/slice/bubbles'
import { userActions } from '@store/slice/user'
import { useSocketContext } from 'hook/useSocketContext'
import { useCallback, useRef, useState } from 'react'
import styles from './components.module.scss'

export const ClickButton = () => {
  const dispatch = useAppDispatch()
  const clicks = useAppSelector(state => state.bubblesReducer.clicks)
  const energy = useAppSelector(state => state.userReducer.user!.energy)
  const houses = useAppSelector(state => state.userReducer.user!.houses) ?? []
  const turboBoostEndedAt = useAppSelector(
    state => state.userReducer.user!.turboBoostEndedAt,
  )
  const bubblesItems = useAppSelector(
    state => state.bubblesReducer.bubblesItems,
  )
  const isBlocked = useAppSelector(state => state.userReducer.user!.isBlocked)
  const { sendJsonMessage } = useSocketContext()

  const clickTimeRef = useRef<number[]>([])
  const totalDifferenceRef = useRef<number[]>([])
  const [isAutoClickerDetected, setIsAutoClickerDetected] = useState(isBlocked)

  const getHouseBubbles = (turbo: number, energy: number) => {
    let newEnergy = energy
    const newBubbles = houses.map(house => {
      const grade = GRADE[house.type as keyof typeof GRADE]

      newEnergy -= house.multiplier

      return {
        icon: `/assets/img/coins/${house.name}.svg`,
        x: house.multiplier * turbo,
        color: grade.color,
        title: house.name,
        type: house.type,
        coinIndex: house.name + clicks,
        speed: bubblesItems.length >= 10 ? 2 : 1,
      }
    })
    return {
      newBubbles,
      newEnergy,
    }
  }

  const handleClick = useCallback(
    async (e: React.MouseEvent<HTMLDivElement>) => {
      if (energy <= 0) return

      if (isAutoClickerDetected) {
        return
      }

      const clickTimeStamp = e.timeStamp
      const clicks = clickTimeRef.current

      if (clicks.length >= 5) {
        let differenceClick = 0
        for (let i = 1; i < clicks.length; i++) {
          const difference = Math.abs(clicks[i] - clicks[i - 1])
          differenceClick += difference
        }

        differenceClick = differenceClick / (clicks.length - 1)
        const totalDifferenceCount = [
          ...totalDifferenceRef.current,
          differenceClick,
        ]

        if (totalDifferenceCount.length >= 5) {
          let totalDifference = 0
          for (let i = 1; i < totalDifferenceCount.length; i++) {
            const difference = Math.abs(
              totalDifferenceCount[i] - totalDifferenceCount[i - 1],
            )
            totalDifference += difference
          }
          totalDifference = totalDifference / (totalDifferenceCount.length - 1)

          if (totalDifference > 0 && totalDifference <= 2) {
            setIsAutoClickerDetected(true)
            sendJsonMessage({ action: 'auto_clicker_detected' })
            dispatch(userActions.setBlocked())
            return
          } else {
            totalDifferenceRef.current.length = 0
          }
        } else {
          totalDifferenceRef.current = totalDifferenceCount
        }

        clickTimeRef.current.length = 0
      } else {
        clickTimeRef.current.push(clickTimeStamp)
      }

      const isActiveTurboBoost = turboBoostEndedAt
        ? new Date(turboBoostEndedAt).getTime() > Date.now()
        : false

      const { newBubbles, newEnergy } = getHouseBubbles(
        isActiveTurboBoost ? 2 : 1,
        energy,
      )

      if (newEnergy < 0) {
        return
      }

      dispatch(bubblesActions.incrementClick())
      dispatch(userActions.decrementEnergy(newEnergy))
      dispatch(bubblesActions.addBubbles(newBubbles as any))
      dispatch(bubblesActions.setLastClickedTime())

      Telegram.WebApp.HapticFeedback.notificationOccurred('success')
    },
    [energy, getHouseBubbles, isAutoClickerDetected, turboBoostEndedAt],
  )

  return (
    <div
      onClick={handleClick}
      style={{ pointerEvents: energy === 0 ? 'none' : 'auto' }}
      className={styles.click_btn}>
      <img src="/assets/img/main/bubbles/bubble.svg" alt="tap here" />
    </div>
  )
}
