import { useToggle } from 'hook/useToggle'
import { SectionItem } from '@parts/SectionItem/SectionItem'
import { useTranslation } from 'react-i18next'
import { UploadPhotoDrawer } from './UploadPhotoDrawer'
import { Thanks } from './Thanks'

export const Tasks = () => {
  const [isOpen, toggleIsOpen] = useToggle()
  const [isOpenThk, toggleIsOpenThk] = useToggle()

  const { t } = useTranslation()

  return (
    <>
      <SectionItem
        onClick={toggleIsOpen}
        icon="/assets/img/settings/tasks.png"
        title={t('New task')}
        description="+2000 PEPE"
        descriptionIcon="/assets/img/coins/PEPE.svg"
        positionInList="other"
        actionAdditionalIcon="/assets/img/common_icons/fire_emoji.png"
      />
      <UploadPhotoDrawer
        reward="100,000 PEPE"
        isOpen={isOpen}
        onClose={toggleIsOpen}
        onSended={toggleIsOpenThk}
      />
      <Thanks isOpen={isOpenThk} onClose={toggleIsOpenThk} />
    </>
  )
}
