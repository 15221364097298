import { Button } from '@parts/Button'
import heic2any from 'heic2any'
import { useRef } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import styles from './PhotoInput.module.scss'

const validEx = ['jpeg', 'jpg', 'png', 'heic']

const convertHeic = async (file: File) => {
  const res = await heic2any({
    blob: file,
    toType: 'image/jpeg', // or image/png
    quality: 0.8,
  })
  return res
}

const validationTypeDocument = (type: string): string | false => {
  const typeSplit = type.split('/')
  if (typeSplit[1] && validEx.includes(typeSplit[1])) {
    return typeSplit[1]
  } else {
    return false
  }
}

interface IPhotoInput {
  uploadedImg: string | ArrayBuffer | null
  name: string | null
  onImgUploaded: (data: {
    name: string | null
    data: string | ArrayBuffer | null
  }) => void
}

export const PhotoInput = ({
  uploadedImg,
  name,
  onImgUploaded,
}: IPhotoInput) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    if (inputRef.current && !uploadedImg) {
      inputRef.current.click()
    }
  }

  const handleOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const acceptedFiles = e.target.files
    if (!acceptedFiles?.length) return

    const fileName = acceptedFiles[0].name
    let file: File | Blob | Blob[] = acceptedFiles[0]
    const typeImage = validationTypeDocument(file.type)

    if (!typeImage) {
      toast.error(
        t(`Доступные форматы: {{formats}}`, {
          formats: validEx.join(', ').slice(0, -1),
        }),
      )
      return
    }

    if (typeImage === 'heic') {
      file = await convertHeic(file as File)
    }

    const reader = new FileReader()

    reader.onload = () => {
      const result = reader.result
      if (result) onImgUploaded({ data: result, name: fileName })
    }
    reader.onerror = console.log
    reader.readAsDataURL(file as Blob | File)

    e.target.value = ''
  }

  const handleDelete = () => onImgUploaded({ data: null, name: null })

  return (
    <>
      <Button onClick={handleClick} styleType="grey">
        <div
          style={{ justifyContent: uploadedImg ? 'space-between' : 'center' }}
          className={styles.button}>
          <div className={styles.button_left}>
            <img src="/assets/img/common_icons/upload_photo.svg" alt="upload" />
            <p>{uploadedImg ? name : t('Upload screenshot')}</p>
          </div>
          {uploadedImg && (
            <img
              onClick={handleDelete}
              src="/assets/img/common_icons/close.svg"
              alt="close"
            />
          )}
        </div>
      </Button>
      <input
        className={styles.input}
        ref={inputRef}
        type="file"
        onChange={handleOnChange}
      />
    </>
  )
}
