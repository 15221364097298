import { SectionItem } from '@parts/SectionItem/SectionItem'
import { useToggle } from 'hook/useToggle'
import { useTranslation } from 'react-i18next'
import { BoostDrawer } from '../BoostDrawer'
import { useLimitEnergyActive } from './useLimitEnergyActive'
import { useLimitEnergyBuy } from './useLimitEnergyBuy'

export const LimitEnergy = () => {
  const { t } = useTranslation()

  const [isOpen, toggleIsOpen] = useToggle()

  const { handleLimitEnergy, isLoadingOnActive } = useLimitEnergyActive()

  const { disabledBuyButton, handleBuy, isLoadingOnBuy, isChecked } =
    useLimitEnergyBuy()

  return (
    <>
      <SectionItem
        onClick={toggleIsOpen}
        description={t('50 друзей или 3')}
        icon="/assets/img/boost/energy.png"
        isLoading={isLoadingOnActive}
        title={'Буст на 300% больше энергии'}
        descriptionIcon="/assets/img/coins/TON.svg"
      />
      <BoostDrawer
        boostType="ENERGY_LIMIT"
        isOpen={isOpen}
        close={toggleIsOpen}
        isChecked={isChecked}
        handleSwitcher={handleLimitEnergy}
        disabledBuyButton={disabledBuyButton}
        isLoadingOnBuy={isLoadingOnBuy}
        handleBuy={async () => await handleBuy(3).then(toggleIsOpen)}
      />
    </>
  )
}
