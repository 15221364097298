import { PageTitle } from '@parts/PageTitle'
import { useTranslation } from 'react-i18next'
import styles from './Boost.module.scss'
import { BoostHeaderInfo } from './BoostHeaderInfo'
import { EnergyRecovery } from './EnergyRecovery/EnergyRecovery'
import { LimitEnergy } from './LimitEnergy/LimitEnergy'
import { TapBot } from './TapBot/TapBot'
import { Turbo } from './Turbo/Turbo'

export const Boost = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.settings}>
      <PageTitle Element={<BoostHeaderInfo />} />
      <div className={styles.sections}>
        <div className={styles.section_with_title}>
          <h3>{t('УСИЛЕНИЯ')}</h3>
          <div className={styles.boosts}>
            <TapBot />
            <LimitEnergy />
            <Turbo />
            <EnergyRecovery />
            {/* <ChargingSpeed /> */}
          </div>
        </div>
      </div>
    </div>
  )
}
