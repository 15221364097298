import { useSetWalletMutation } from '@api/endpoints/game'

export const useSetWallet = () => {
  const [mutate, { isLoading }] = useSetWalletMutation()

  const handleSetWallet = async (address: string, walletName: string) => {
    if (isLoading) {
      return
    }

    try {
      await mutate({ address, walletName }).unwrap()
    } catch (error) {
      console.error(`Set wallet error`, error)
    }
  }

  return { handleSetWallet }
}
