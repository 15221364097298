import { type ICoinKey } from '@enums/coin'

export const isAvailableToBuyHouse = (house: ICoinKey, level: number) => {
  switch (house) {
    case 'WIF':
      return level >= 10
    case 'SHIB':
      return level >= 15
    case 'DOGE':
      return level >= 20
    default:
      return false
  }
}
