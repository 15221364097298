import { useTranslation } from 'react-i18next'
import styles from './Disclaimer.module.scss'

interface IDisclaimer {
  title?: string
  text: string
}
export const Disclaimer = ({ title = 'Disclaimer', text }: IDisclaimer) => {
  const { t } = useTranslation()
  return (
    <div className={styles.body}>
      <h4>{t(title)}:</h4>
      <p>{t(text)}</p>
    </div>
  )
}
