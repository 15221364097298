import { useTimer } from 'react-timer-hook'
import styles from './Timer.module.scss'
import { useAppSelector } from '@store/hook'
import { useEffect } from 'react'

const processingTime = (time: number) =>
  String(time).length === 1 ? '0' + time : time

export const Timer = () => {
  const turboBoostEndedAt = useAppSelector(
    state => state.userReducer.user!.turboBoostEndedAt,
  )

  const { hours, minutes, seconds, restart } = useTimer({
    autoStart: false,
    expiryTimestamp: new Date(),
  })

  useEffect(() => {
    if (turboBoostEndedAt) {
      const expiryDate = new Date(turboBoostEndedAt)
      restart(expiryDate, true)
    }
  }, [turboBoostEndedAt])

  if (seconds === 0 && minutes === 0 && hours === 0) return

  return (
    <div className={styles.body}>
      <img src="/assets/img/main/timer.svg" alt="timer" />
      <div className={styles.body_values}>
        <p>{processingTime(hours)}:</p>
        <p>{processingTime(minutes)}:</p>
        <p>{processingTime(seconds)}</p>
      </div>
    </div>
  )
}
