import { useToggleLevelMutation } from '@api/endpoints/game'
import { useAppSelector } from '@store/hook'
import clsx from 'clsx'
import styles from './SetUserLvl.module.scss'

export const SetUserLvl = () => {
  const level = useAppSelector(state => state.userReducer.user?.level) ?? 1

  const [toggleLevel, { isLoading }] = useToggleLevelMutation()

  const handleIncrement = async () => {
    if (isLoading) {
      return
    }

    if (level < 25) {
      try {
        await toggleLevel(level + 1).unwrap()
      } catch (error) {
        console.log('Increment level error', error)
      }
    }
  }

  const handleDecrement = async () => {
    if (isLoading) {
      return
    }

    if (level > 1) {
      try {
        await toggleLevel(level - 1).unwrap()
      } catch (error) {
        console.log('Increment level error', error)
      }
    }
  }

  return (
    <div
      className={clsx(styles.body, {
        [styles.loading]: isLoading,
      })}>
      <span data-disabled={level >= 25} onClick={handleIncrement}>
        +
      </span>
      <p>{level}</p>
      <span data-disabled={level <= 1} onClick={handleDecrement}>
        -
      </span>
    </div>
  )
}
