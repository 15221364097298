import { useAppSelector } from '@store/hook'
import clsx from 'clsx'
import styles from './components.module.scss'

const getWidthEnergyText = (energy: number) => String(energy).length + '0px'

interface Props {
  energy?: number
  maxEnergy?: number
}

export const EnergyBar = ({ energy, maxEnergy }: Props) => {
  const realEnergy = useAppSelector(state => state.userReducer.user!.energy)
  const realMaxEnergy = useAppSelector(
    state => state.userReducer.user!.maxEnergy,
  )
  const additionalEnergy = useAppSelector(
    state => state.userReducer.user!.additionalEnergy,
  )

  const customEnergy = energy ?? realEnergy
  const customMaxEnergy = maxEnergy ?? realMaxEnergy

  const energyWidth = (customEnergy / customMaxEnergy) * 100

  const additionalEnergyWidth = Number(
    (
      (additionalEnergy /
        (additionalEnergy + (customMaxEnergy - additionalEnergy))) *
      100
    ).toFixed(2),
  )

  const getAdditionalEnergyWidth = () => {
    const diff = 100 - energyWidth
    const threshold = 100 - additionalEnergyWidth

    if (energyWidth < threshold) {
      return '0%'
    }

    return `${(additionalEnergyWidth - diff).toFixed(2)}%`
  }

  return (
    <div
      data-energy-bar={true}
      className={clsx(styles.item_wrapper, styles.energy)}>
      <img
        className={styles.energy_icon}
        src="/assets/img/main/energy.svg"
        alt="energy"
      />
      <span
        data-energy={customEnergy}
        style={{ width: getWidthEnergyText(customEnergy) }}
        className={clsx(styles.text, styles.energy_text)}>
        {customEnergy}
      </span>
      <div data-progress={true} className={styles.progress}>
        <div
          className={styles.progress_line}
          style={{
            width: energyWidth + '%',
          }}
        />
        <div
          style={{
            width: getAdditionalEnergyWidth(),
            left: 100 - additionalEnergyWidth + '%',
          }}
          className={clsx(
            styles.progress_line,
            styles.progress_line_additional,
          )}
        />
      </div>
    </div>
  )
}
