import { GRADE_VALUE } from '@enums/gradeValue'
import { BoostDrawer } from '@pages/boost/BoostDrawer'
import { SectionItem } from '@parts/SectionItem/SectionItem'
import { useAppSelector } from '@store/hook'
import { useBuyHouseByTon } from 'hook/useBuyHouseByTon'
import { useToggle } from 'hook/useToggle'
import { useTranslation } from 'react-i18next'

export const OpenDogWif = () => {
  const { t } = useTranslation()
  const [isOpen, toggleIsOpen] = useToggle()
  const referrals = useAppSelector(state => state.userReducer.user!.referrals)

  const friends = referrals.length
  const needFriends = 10

  const { handleBuy, isLoadingOnBuy, isHasHouse } = useBuyHouseByTon()

  const isHasWifHouse = isHasHouse('WIF')

  return (
    <>
      <SectionItem
        description={t('Пригласи 10 друзей и открой монету')}
        icon="/assets/img/coins/WIF_old.svg"
        isCompleted={friends >= 10 || isHasWifHouse}
        isLoading={isLoadingOnBuy}
        title={t('Открой Dogwif')}
        onClick={
          needFriends === friends || isHasWifHouse ? () => {} : toggleIsOpen
        }
        positionInList="other"
      />
      <BoostDrawer
        isOpen={isOpen}
        close={toggleIsOpen}
        gradeTo={GRADE_VALUE.COMMON}
        boostType={'OPEN_WIF'}
        handleBuy={async () => await handleBuy(0.1, 'WIF').then(toggleIsOpen)}
        isLoadingOnBuy={isLoadingOnBuy}
        disabledBuyButton={isHasWifHouse}
        isChecked={isHasWifHouse}
      />
    </>
  )
}
