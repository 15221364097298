import { BottomDrawer } from '@parts/BottomDraver'
import { Button } from '@parts/Button'
import { Disclaimer } from '@parts/Disclaimer'
import { useTranslation } from 'react-i18next'
import { PhotoInput } from '@parts/PhotoInput/PhotoInput'
import { useState } from 'react'
import styles from './Tasks.module.scss'

interface IUploadPhotoDrawer {
  isOpen: boolean
  reward: string
  onClose: () => void
  onSended: () => void
}

export const UploadPhotoDrawer = ({
  isOpen,
  onClose,
  onSended,
  reward,
}: IUploadPhotoDrawer) => {
  const { t } = useTranslation()
  const [photo, setPhoto] = useState<{
    name: null | string
    data: null | string | ArrayBuffer
  }>({
    name: null,
    data: null,
  })

  const handleSend = () => {
    onClose()
    onSended()
    setPhoto({ data: null, name: null })
  }

  const handleOpenTask = () => {}

  return (
    <BottomDrawer isOpen={isOpen} onClose={onClose}>
      <div className={styles.body}>
        <img
          width={147}
          height={110}
          className={styles.body_icon}
          src="/assets/img/settings/tasks_bg.png"
          alt="decoration"
        />

        <h2 className="drawer_title">{t('Tasks to complete')}</h2>
        <p className="drawer_description">
          {t(
            'Leave a comment on YouTube expressing your positive attitude towards Prick.',
          )}
        </p>

        <div className={styles.body_cost}>
          <p>{t('Reward')}</p>
          <div className={styles.body_cost_inner}>
            <img src="/assets/img/coins/PEPE.svg" alt="pepe" />
            {reward}
          </div>
        </div>

        <div className={styles.body_buttons}>
          {!photo.data && (
            <Button onClick={handleOpenTask} styleType="blue">
              {t('TASK HERE')}
            </Button>
          )}
          <PhotoInput
            onImgUploaded={setPhoto}
            uploadedImg={photo.data}
            name={photo.name}
          />
          {photo.data && (
            <Button onClick={handleSend} styleType="blue">
              {t('SEND')}
            </Button>
          )}
        </div>
        <Disclaimer text="Upload a screenshot of the completed task. All tasks are manually checked. Cheating will result in access to the app being blocked!" />
      </div>
    </BottomDrawer>
  )
}
