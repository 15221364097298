import { type ICoinKey } from '@enums/coin'
import { useAppDispatch, useAppSelector } from '@store/hook'
import { bubblesActions, type IBubbleItem } from '@store/slice/bubbles'
import clsx from 'clsx'
import { memo, useEffect, useState } from 'react'
import styles from './BubbleContainer.module.scss'

export interface IBubble {
  icon: string
  x: number
  color: keyof typeof colors
  title: ICoinKey
}
const colors = {
  green: 'rgba(65, 255, 153)',
  blue: 'rgba(0, 163, 255)',
  purple: 'rgba(172, 65, 255)',
  yellow: 'rgba(255, 230, 0)',
  bright_green: 'rgba(143, 255, 0)',
}

// const BUBBLE_SIZE = 56

// const coinOrder: Record<ICoinKey, number> = {
//   [COIN.PEPE]: 1,
//   [COIN.DOGE]: 2,
//   [COIN.SHIB]: 3,
//   [COIN.BONK]: 4,
//   [COIN.WIF]: 5,
// }

// const getXStartPosition = (coin: ICoinKey) => {
//   const order = coinOrder[coin]
//   const fullRange = window.innerWidth
//   const oneCardSize = fullRange / Object.values(coinOrder).length
//   return oneCardSize * order - oneCardSize / 2 - BUBBLE_SIZE / 2
// }

// const getRandomPosition = () => {
//   const windowHeight = window.innerHeight
//   const windowWidth = window.innerWidth

//   const Y =
//     getRandomInt(
//       FOOTER_HEIGHT,
//       windowHeight - HEADER_HEIGHT - SAVE_PADDINGS - BUBBLE_SIZE,
//     ) + 'px'
//   const X =
//     getRandomInt(SAVE_PADDINGS, windowWidth - BUBBLE_SIZE - SAVE_PADDINGS) +
//     'px'

//   return { X, Y }
// }

export const Bubble = memo(
  ({ icon, x, title, coinIndex, type, speed }: IBubbleItem) => {
    const [hide, setHide] = useState(false)
    const dispatch = useAppDispatch()
    const coins = useAppSelector(state => state.bubblesReducer.coins)
    const houses = useAppSelector(state => state.userReducer.user?.houses) ?? []
    const turboBoostEndedAt = useAppSelector(
      state => state.userReducer.user!.turboBoostEndedAt,
    )

    // const [isBubbleDisable, setIsBubbleDisable] = useState(false)
    // const [position, setPosition] = useState({
    //   X: getXStartPosition(title) + 'px',
    //   Y: -BUBBLE_SIZE + 'px',
    // })

    // useEffect(() => {
    //   const timeout = setTimeout(() => {
    //     setPosition(getRandomPosition())
    //   }, 500)

    //   return () => {
    //     if (timeout) clearTimeout(timeout)
    //   }
    // }, [])

    // if (isBubbleDisable) return null

    // const handleClick = () => {
    //   setHide(true)
    // }

    // const handleTransitionEnd = (e: React.TransitionEvent) => {
    //   if (e.propertyName === 'opacity') setIsBubbleDisable(true)
    // }

    const handleTransitionEnd = (e: React.TransitionEvent) => {
      if (e.propertyName === 'opacity') {
        const updatedCoins: Record<string, number> = {}
        houses.forEach(house => {
          updatedCoins[house.name] =
            coins[house.name as keyof typeof coins] + house.multiplier
        })

        const incrementHouse = houses.find(house => house.name === title)

        if (!incrementHouse) {
          return
        }

        const isActiveTurboBoost = turboBoostEndedAt
          ? new Date(turboBoostEndedAt).getTime() > new Date().getTime()
          : false

        const turboBoostMultiplier = isActiveTurboBoost ? 2 : 1

        dispatch(
          bubblesActions.incrementCoinAmount({
            coin: incrementHouse.name as ICoinKey,
            multiplier: incrementHouse.multiplier * turboBoostMultiplier,
          }),
        )
        dispatch(bubblesActions.removeBubbles(coinIndex))
      }
    }

    useEffect(() => {
      setHide(true)
    }, [])

    return (
      //  CUSTOM BUBBLE
      <div
        style={{
          transition:
            speed === 2
              ? 'left 3s ease 0s, bottom 3s ease 0s, opacity 0.5s ease 1s'
              : 'left 3s ease 0s, bottom 3s ease 0s, opacity 1s ease 1s',
        }}
        onTransitionEnd={handleTransitionEnd}
        className={clsx(styles.bubble, styles[type], styles[title], {
          [styles.hide]: hide,
        })}>
        <div className={styles.bubble_bg}>
          <img
            className={styles.bubble_bg_blicks}
            src={`/assets/img/common_icons/glar_${title}.svg`}
            alt="glar"
          />
        </div>
        <div className={styles.bubble_content}>
          <img src={icon} alt="coin" />
          <p>X{x}</p>
        </div>
      </div>

      // SVG BUBBLE
      // <div
      //   // onClick={handleClick}
      //   // style={{
      //   //   left: getXStartPosition(title) + 'px',
      //   // }}
      //   className={clsx(styles.bubble, styles[title], {
      //     [styles.hide]: hide,
      //   })}>
      //   <svg
      //     width="56"
      //     height="56"
      //     viewBox="0 0 56 56"
      //     fill="none"
      //     xmlns="http://www.w3.org/2000/svg">
      //     <circle cx="28" cy="28" r="28" fill="url(#paint0_linear_118_513)" />
      //     <circle
      //       cx="28"
      //       cy="28"
      //       r="28"
      //       fill="url(#paint1_linear_118_513)"
      //       fillOpacity="0.1"
      //     />
      //     <circle
      //       cx="28"
      //       cy="28"
      //       r="28"
      //       fill="url(#paint2_radial_118_513)"
      //       fillOpacity="0.1"
      //     />
      //     <path
      //       d="M30.9274 0.141058C29.9393 0.070529 28.9511 0 27.963 0C13.6347 0 1.6357 10.7909 0.153473 25.1083C-0.622934 32.5139 1.56512 39.8489 6.29415 45.6322C11.0232 51.4156 17.7285 55.0831 25.1397 55.8589C26.1278 55.9295 27.116 56 28.0335 56C42.4324 56 54.3608 45.2091 55.843 30.8917C57.4664 15.5164 46.3144 1.76322 30.9274 0.141058ZM54.3608 30.7506C52.8786 45.2796 39.8208 55.8589 25.2808 54.3073C10.7408 52.8262 0.153473 39.7783 1.6357 25.2494C3.18852 10.7204 16.1757 0.141058 30.7863 1.62217C45.3262 3.1738 55.9136 16.1511 54.3608 30.7506Z"
      //       fill="#A9FFFF"
      //     />
      //     <path
      //       d="M11.5791 11.8489C9.32214 13.0479 7.20628 14.3879 6.43046 16.9975C5.86623 19.0428 6.00728 21.0882 8.4758 21.0882C11.3675 21.0882 16.5866 16.0806 14.6823 12.9068C13.2718 10.6499 10.239 12.6247 8.75791 13.7532L11.5791 11.8489Z"
      //       fill="#A9FFFF"
      //     />
      //     <path
      //       d="M5.37248 22.6398C3.60926 24.6851 5.79566 27.5768 7.62941 25.1788C9.25158 23.063 5.86619 20.665 4.94931 23.204L5.37248 22.6398Z"
      //       fill="#A9FFFF"
      //     />
      //     <g style={{ mixBlendMode: 'color' }}>
      //       <rect width="56" height="56" rx="28" fill={colors[color]} />
      //     </g>

      //     <defs>
      //       <linearGradient
      //         id="paint0_linear_118_513"
      //         x1="28"
      //         y1="0"
      //         x2="29.4177"
      //         y2="37.5696"
      //         gradientUnits="userSpaceOnUse">
      //         <stop stopColor="#52D8E9" />
      //         <stop offset="1" stopColor="#52D8E9" stopOpacity="0" />
      //       </linearGradient>
      //       <linearGradient
      //         id="paint1_linear_118_513"
      //         x1="28.3544"
      //         y1="56"
      //         x2="28.3544"
      //         y2="26.2278"
      //         gradientUnits="userSpaceOnUse">
      //         <stop stopColor="#C2F8FF" />
      //         <stop offset="1" stopColor="#52D8E9" stopOpacity="0" />
      //       </linearGradient>
      //       <radialGradient
      //         id="paint2_radial_118_513"
      //         cx="0"
      //         cy="0"
      //         r="1"
      //         gradientUnits="userSpaceOnUse"
      //         gradientTransform="translate(22.3818 12.9885) rotate(97.8172) scale(21.2341)">
      //         <stop stopColor="white" />
      //         <stop offset="1" stopColor="white" stopOpacity="0" />
      //       </radialGradient>
      //     </defs>
      //   </svg>
      //   <div
      //     onTransitionEnd={handleTransitionEnd}
      //     className={styles.bubble_content}>
      //     <img src={icon} alt="coin" />
      //     <p>X{x}</p>
      //   </div>
      // </div>
    )
  },
)

Bubble.displayName = 'Bubble'
