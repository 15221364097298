import { Button } from '@parts/Button'
import { PageTitle } from '@parts/PageTitle'
import { ROUTES } from '@utils/routes'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './PreMarket.module.scss'

export const PreMarket = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const redirect = () => {
    navigate(ROUTES.MAIN)
  }

  return (
    <div className={styles.pre_market}>
      <PageTitle
        imgSize={{ width: 267, height: 170 }}
        className={styles.pre_market_title}
        img={'/assets/img/pre_market/pre_market_bg.png'}
        title={'Premarket will become available after mining all coins.'}
        subTitle={
          'To withdraw tokens to the exchange, you must be subscribed to the telegram channel for at least 14 days.'
        }
      />

      <div className={styles.pre_market_btn}>
        <Button styleType="blue" onClick={redirect}>
          {t('OK')}
        </Button>
      </div>
    </div>
  )
}
