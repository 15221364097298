import { useTapBotMutation } from '@api/endpoints/game'
import { useAppSelector } from '@store/hook'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

export const useTapBotActive = () => {
  const { t } = useTranslation()

  const [tapBot, { isLoading }] = useTapBotMutation()
  const tapBotBoost = useAppSelector(
    state => state.userReducer.user!.tapBotBoost,
  )
  const isBuyTapBotBoost = useAppSelector(
    state => state.userReducer.user!.isBuyTapBotBoost,
  )

  const handleTapBot = async () => {
    if (isLoading) {
      toast.error(t('Loading...'))
      return
    }

    if (!isBuyTapBotBoost) {
      toast.error(t('Буст не куплен'))
      return
    }

    toast.success(t(`Boost ${tapBotBoost ? 'deactivated' : 'activated'}`))

    try {
      await tapBot().unwrap()
    } catch (error) {
      console.log('Charging speed boost error', error)
    }
  }

  return { handleTapBot, isLoading }
}
