/* eslint-disable no-irregular-whitespace */
import { useAppSelector } from '@store/hook'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

const inviteMessages = {
  en: "Hello! \nCome in soon, in Ton Meme World! \nHere you can also farm coins for free: Pepe, Bonk, Dogwif, Shiba, Doge. \nJust playing clicker, if you missed NotCoin, then don't miss Ton Meme World. \nA freebie based on TON!",
  ru: 'Привет! \nЗаходи скорее, в Ton Meme World! \nТут еще можно бесплатно можно фармить монеты: Pepe, Bonk, Dogwif, Shiba, Doge. \nПросто играя в кликер, если ты упустил NotCoin, то не упускай Ton Meme World. \nХалява на базе TON!',
}

export const useInvite = () => {
  const { t } = useTranslation()
  const refLink = useAppSelector(state => state.userReducer.user!.refLink)
  const language = useAppSelector(state => state.userReducer.user!.language)

  const handleInvite = async () => {
    try {
      if (
        Telegram.WebApp.platform === 'android' ||
        Telegram.WebApp.platform === 'android_x'
      ) {
        await navigator.clipboard.writeText(refLink)
        toast.success(t('Ссылка скопирована'))
      } else {
        Telegram.WebApp.openTelegramLink(
          `https://t.me/share/url?text=${encodeURIComponent(
            language === 'ru' ? inviteMessages.ru : inviteMessages.en,
          )}&url=${refLink}`,
        )
      }
    } catch (e) {
      console.log('Navigator error', e)
    }
  }

  const handleCopyRefLink = async () => {
    try {
      await navigator.clipboard.writeText(refLink)
      toast.success(t('Ссылка скопирована'))
    } catch (e) {
      console.log('error on navigator copy ref link', e)
    }
  }

  return { handleInvite, handleCopyRefLink }
}
