import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { type RootState } from '@store/hook'
import { getItemFromLocalStorage } from '@utils/localStorageService'

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.REACT_APP_URL_API ?? 'https://swagerbyfalio.com/mem/v1',
    prepareHeaders: (headers, api) => {
      const telegramId = getItemFromLocalStorage('telegramId')

      const telegramIdFromStore = (api.getState() as RootState)?.userReducer
        ?.user?.id

      const id = telegramId || telegramIdFromStore

      if (id) {
        headers.set('Authorization', `Bearer ${id}`)
      }

      headers.set('TMW-Origin', window.location.origin)

      return headers
    },
  }),
  endpoints: () => ({}),
})
