import { type COIN } from '@enums/coin'
import { useAppSelector } from '@store/hook'
import { useTranslation } from 'react-i18next'
import styles from './Boost.module.scss'

export const BoostHeaderInfo = () => {
  const { t } = useTranslation()
  const coins = useAppSelector(state => state.bubblesReducer.coins)

  const listCoins: Partial<typeof coins> = { ...coins }
  delete listCoins.TON

  return (
    <div>
      <p className={styles.coins_list_title}>{t('Ваш баланс монет')}</p>
      <ul className={styles.coins_list}>
        {Object.keys(listCoins).map(coin => (
          <li key={coin}>
            <img
              width={24}
              height={24}
              src={`/assets/img/coins/${coin}.svg`}
              alt={coin}
            />
            {coins[coin as keyof typeof COIN]}
          </li>
        ))}
      </ul>
    </div>
  )
}
