import { TonConnectUIProvider } from '@tonconnect/ui-react'

interface Props {
  children: React.ReactNode
}

export const TonConnectProvider = ({ children }: Props) => {
  return (
    <TonConnectUIProvider
      manifestUrl={`${window.location.origin}/tonconnect-manifest.json`}
      actionsConfiguration={{
        twaReturnUrl: 'https://t.me/ton_meme_world_dev_bot/myapp',
      }}>
      {children}
    </TonConnectUIProvider>
  )
}
