import { useTurboBuyMutation } from '@api/endpoints/game'
import { useTransaction } from 'hook/useTransaction'

export const useTurboBuy = () => {
  const { handleTransaction } = useTransaction()

  const [mutate, { isLoading }] = useTurboBuyMutation()

  const handleBuy = async (amount: number) => {
    if (isLoading) {
      return
    }

    await handleTransaction(amount, async boc => {
      await mutate({
        boc,
        amount,
      })
    })
  }

  return { handleBuy, isLoadingOnBuy: isLoading }
}
