import { Loader } from './Loader'
import styles from './Loader.module.scss'

interface Props {
  isLoading: boolean
}

export const RequestLoader = ({ isLoading }: Props) => {
  if (!isLoading) {
    return
  }

  return (
    <div className={styles.request_loader}>
      <Loader />
    </div>
  )
}
