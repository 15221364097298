import { useFullRecoveryMutation } from '@api/endpoints/game'
import { useAppSelector } from '@store/hook'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer-hook'

export const useEnergyRecoveryActive = () => {
  const { t } = useTranslation()
  const nextFreeEnergyRecoveryBoost = useAppSelector(
    state => state.userReducer.user!.nextFreeEnergyRecoveryBoost,
  )
  const energy = useAppSelector(state => state.userReducer.user!.energy)
  const maxEnergy = useAppSelector(state => state.userReducer.user!.maxEnergy)

  const [fullEnergy, { isLoading }] = useFullRecoveryMutation()

  const isNext = nextFreeEnergyRecoveryBoost
    ? new Date().getTime() > new Date(nextFreeEnergyRecoveryBoost).getTime()
    : true

  const { seconds, minutes, hours, restart, isRunning } = useTimer({
    expiryTimestamp: nextFreeEnergyRecoveryBoost
      ? new Date(nextFreeEnergyRecoveryBoost)
      : new Date(),
    autoStart: true,
  })

  useEffect(() => {
    if (nextFreeEnergyRecoveryBoost) {
      const expiryDate = new Date(nextFreeEnergyRecoveryBoost)
      restart(expiryDate)
    }
  }, [nextFreeEnergyRecoveryBoost])

  const handleEnergyRecovery = async () => {
    if (isLoading) {
      toast.error(t('Loading...'))
      return
    }

    if (isRunning) {
      return
    }

    if (!isNext) {
      toast.error(t('Not available'))
      return
    }

    if (energy === maxEnergy) {
      toast.error(t('Energy is full'))
      return
    }

    toast.success(t('Boost activated'))

    try {
      await fullEnergy().unwrap()
    } catch (error) {
      console.log('Full energy boost error', error)
    }
  }

  const isChecked = energy === maxEnergy

  const disabledActiveButton = !isNext

  const buttonText =
    (minutes !== 0 || seconds !== 0 || hours !== 0) && isRunning
      ? hours.toString().padStart(2, '0') +
        ':' +
        minutes.toString().padStart(2, '0') +
        ':' +
        seconds.toString().padStart(2, '0')
      : t('Активировать')

  return {
    handleEnergyRecovery,
    isLoadingOnActive: isLoading,
    isChecked,
    disabledActiveButton,
    buttonText,
  }
}
