import { COIN, type ICoinKey } from '@enums/coin'
import { GRADE_VALUE, type IGradeValueKey } from '@enums/gradeValue'

// @ts-expect-error fix ton
export const PRICES: {
  [key in ICoinKey]: {
    [key in IGradeValueKey]: {
      amount: number
      type: '' | 'COINS' | 'TON'
      needInvite?: boolean
    }
  }
} = {
  [COIN.PEPE]: {
    [GRADE_VALUE.COMMON]: {
      amount: 0,
      type: '',
    },
    [GRADE_VALUE.RARE]: {
      amount: 1000,
      type: 'COINS',
    },
    [GRADE_VALUE.LEGENDARY]: {
      amount: 10_000,
      type: 'COINS',
    },
    [GRADE_VALUE.IMMORTAL]: {
      amount: 0.1,
      type: 'TON',
    },
    [GRADE_VALUE.ARCANA]: {
      amount: 1,
      type: 'TON',
    },
  },
  [COIN.BONK]: {
    [GRADE_VALUE.COMMON]: {
      amount: 0,
      type: '',
      needInvite: true,
    },
    [GRADE_VALUE.RARE]: {
      amount: 500,
      type: 'COINS',
    },
    [GRADE_VALUE.LEGENDARY]: {
      amount: 0.1,
      type: 'TON',
    },
    [GRADE_VALUE.IMMORTAL]: {
      amount: 0.5,
      type: 'TON',
    },
    [GRADE_VALUE.ARCANA]: {
      amount: 2,
      type: 'TON',
    },
  },
  [COIN.WIF]: {
    [GRADE_VALUE.COMMON]: {
      amount: 0.1,
      type: 'TON',
      needInvite: true,
    },
    [GRADE_VALUE.RARE]: {
      amount: 1500,
      type: 'COINS',
    },
    [GRADE_VALUE.LEGENDARY]: {
      amount: 0.5,
      type: 'TON',
    },
    [GRADE_VALUE.IMMORTAL]: {
      amount: 2.5,
      type: 'TON',
    },
    [GRADE_VALUE.ARCANA]: {
      amount: 10,
      type: 'TON',
    },
  },
  [COIN.SHIB]: {
    [GRADE_VALUE.COMMON]: {
      amount: 1,
      type: 'TON',
    },
    [GRADE_VALUE.RARE]: {
      amount: 5000,
      type: 'COINS',
    },
    [GRADE_VALUE.LEGENDARY]: {
      amount: 2.5,
      type: 'TON',
    },
    [GRADE_VALUE.IMMORTAL]: {
      amount: 10,
      type: 'TON',
    },
    [GRADE_VALUE.ARCANA]: {
      amount: 50,
      type: 'TON',
    },
  },
  [COIN.DOGE]: {
    [GRADE_VALUE.COMMON]: {
      amount: 10,
      type: 'TON',
    },
    [GRADE_VALUE.RARE]: {
      amount: 25,
      type: 'TON',
    },
    [GRADE_VALUE.LEGENDARY]: {
      amount: 50,
      type: 'TON',
    },
    [GRADE_VALUE.IMMORTAL]: {
      amount: 100,
      type: 'TON',
    },
    [GRADE_VALUE.ARCANA]: {
      amount: 200,
      type: 'TON',
    },
  },
} as const
