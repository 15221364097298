import { useUpgradeHouseMutation } from '@api/endpoints/game'
import { COIN, type ICoinKey } from '@enums/coin'
import { GRADE, type IGradeKey } from '@enums/grade'
import { GRADE_VALUE } from '@enums/gradeValue'
import { BoostDrawer, type IBoostType } from '@pages/boost/BoostDrawer'
import { BottomDrawer } from '@parts/BottomDraver'
import { Button } from '@parts/Button'
import { useAppSelector } from '@store/hook'
import { capitalize } from '@utils/capitalize'
import { formatAmount } from '@utils/formatAmount'
import { PRICES } from '@utils/prices'
import clsx from 'clsx'
import { useBuyHouseByTon } from 'hook/useBuyHouseByTon'
import { useInvite } from 'hook/useInvite'
import { useToggle } from 'hook/useToggle'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import styles from './components.module.scss'

interface ICardGrades {
  value: IGradeKey
  icon: string
  coin: ICoinKey
  isAvailableUpgrade: boolean
  onClose: () => void
}

export const CardGrades = ({
  value,
  icon,
  coin,
  isAvailableUpgrade,
  onClose,
}: ICardGrades) => {
  const [openDrawer, toggleOpenDrawer] = useToggle()
  const coins = useAppSelector(state => state.bubblesReducer.coins)
  const { t } = useTranslation()
  const [upgradeHouse, { isLoading }] = useUpgradeHouseMutation()
  const { handleInvite } = useInvite()

  const { handleBuy, isLoadingOnBuy, isHasHouse } = useBuyHouseByTon()

  if (value === 'COMMON' && coin === 'PEPE') return null

  const isTon = PRICES[coin][value].type === 'TON'
  const housePrice = PRICES[coin][value].amount
  const needInvite = PRICES[coin][value].needInvite

  const onRequestEnd = () => {
    toggleOpenDrawer()
    onClose()
  }

  const handleClick = async () => {
    if (isLoading) {
      return
    }

    if (needInvite) {
      await handleInvite()
      return
    }

    const housePriceCoin = COIN[coin]
    const userBalance = coins[coin]

    if (housePrice > userBalance) {
      toast.error(
        t(`${t('Not enough')} ${housePrice - userBalance} ${housePriceCoin}`),
      )
      return
    }

    try {
      await upgradeHouse(coin).unwrap()
      onRequestEnd()
    } catch (error) {
      console.log(
        `Error ${isAvailableUpgrade ? 'upgrade' : 'buy'} house`,
        error,
      )

      const message = error?.data?.message

      if (typeof message === 'string') {
        toast.error(t(message))
        return
      }

      toast.error(
        t(`Ошибка при ${isAvailableUpgrade ? 'апгрейде' : 'покупке'} дома`),
      )
    }
  }

  const messages = {
    title: {
      upgrade: t('Upgrade {{coin}} TMW', {
        coin: capitalize(coin),
      }),
      invite: t('В 2 раза больше монет за 1 КЛИК!'),
      unblock: t('Want to unblock {{coin}} TMW', {
        coin: capitalize(coin),
      }),
    },
    description: {
      invite: t(
        'Пригласите минимум двух друзей, которые заработают по 100 PEPE каждый',
      ),
      common: t(' If you upgrade you will get Х{{value}} times more coins', {
        value: GRADE[value].x,
      }),
    },
  }

  return (
    <>
      <div
        onClick={toggleOpenDrawer}
        style={{
          borderColor: GRADE[value].color,
          marginBottom: isAvailableUpgrade ? 47 : 26,
        }}
        className={clsx(styles.card, styles.grade_options_card)}>
        <div className={styles.card_body}>
          <img src={icon} alt={coin} />
          <p style={{ color: '#ffffff' }}>{capitalize(coin)} TMW</p>
          <span style={{ color: GRADE[value].color }}>
            {value} <br />
            {GRADE[value].x}X
          </span>
        </div>

        <div className={styles.card_decoration}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM6.46967 12.9697C6.17678 13.2626 6.17678 13.7374 6.46967 14.0303C6.76256 14.3232 7.23744 14.3232 7.53033 14.0303L10 11.5607L12.4697 14.0303C12.7626 14.3232 13.2374 14.3232 13.5303 14.0303C13.8232 13.7374 13.8232 13.2626 13.5303 12.9697L10.5303 9.96967C10.2374 9.67678 9.76256 9.67678 9.46967 9.96967L6.46967 12.9697ZM6.46967 10.0303C6.17678 9.73744 6.17678 9.26256 6.46967 8.96967L9.46967 5.96967C9.76256 5.67678 10.2374 5.67678 10.5303 5.96967L13.5303 8.96967C13.8232 9.26256 13.8232 9.73744 13.5303 10.0303C13.2374 10.3232 12.7626 10.3232 12.4697 10.0303L10 7.56066L7.53033 10.0303C7.23744 10.3232 6.76256 10.3232 6.46967 10.0303Z"
              fill={GRADE[value].color}
            />
          </svg>
        </div>
      </div>
      {needInvite ? (
        <BoostDrawer
          isOpen={openDrawer}
          close={isLoadingOnBuy ? () => {} : toggleOpenDrawer}
          gradeTo={GRADE_VALUE.COMMON}
          boostType={`OPEN_${coin}` as IBoostType}
          handleBuy={async () =>
            await handleBuy(housePrice, coin).then(onRequestEnd)
          }
          isLoadingOnBuy={isLoadingOnBuy}
        />
      ) : (
        <BottomDrawer
          isOpen={openDrawer}
          onClose={isLoading ? () => {} : toggleOpenDrawer}>
          <div className={styles.drawer_card}>
            <p
              className={styles.drawer_card_value}
              style={{
                backgroundColor: GRADE[value].color,
              }}>
              {value} х{GRADE[value].x}
            </p>
            <img src={icon} alt="coin" />
            <h2>
              {
                messages.title[
                  needInvite
                    ? 'invite'
                    : isAvailableUpgrade
                      ? 'upgrade'
                      : 'unblock'
                ]
              }
            </h2>
            <span>
              {messages.description[needInvite ? 'invite' : 'common']}
            </span>
            <div className={styles.drawer_card_cost}>
              {PRICES[coin][value].type === 'TON' && (
                <img src="/assets/img/coins/TON.svg" alt="ton coin" />
              )}
              <span>
                {formatAmount(PRICES[coin][value].amount)}{' '}
                {PRICES[coin][value].type === 'TON' ? COIN.TON : COIN[coin]}
              </span>
            </div>
            <Button
              onClick={async () => {
                isTon
                  ? await handleBuy(
                      housePrice,
                      coin,
                      value,
                      isHasHouse(coin),
                    ).then(onRequestEnd)
                  : await handleClick()
              }}
              loading={isLoading}>
              {t('ПОЛУЧИТЬ')}
            </Button>
          </div>
        </BottomDrawer>
      )}
    </>
  )
}
