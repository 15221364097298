import { GRADE } from '@enums/grade'
import { type IGradeValueKey } from '@enums/gradeValue'
import { BottomDrawer } from '@parts/BottomDraver'
import { Button } from '@parts/Button'
import { MyToggle } from '@parts/MyToggle'
import { useAppSelector } from '@store/hook'
import clsx from 'clsx'
import { useInvite } from 'hook/useInvite'
import { t } from 'i18next'
import styles from './Boost.module.scss'

const drawerData = {
  AUTO_TAP: {
    const: 10,
    needInvites: 100,
    title: 'Робот Автокликер',
    subTitle:
      'Робот тапает 12 часов в сутки без вашего участия - добывай монеты Нон Стоп',
    mainImg: '/assets/img/boost/auto_tap_drawer.png',
    subImg: '/assets/img/boost/auto_tap.png',
    freeAvailableAmount: 0,
  },
  ENERGY_LIMIT: {
    const: 3,
    needInvites: 50,
    title: 'Буст 300%',
    subTitle:
      'Полоска энергии будет увеличена на 300% по сравнению с базовыми характеристиками',
    mainImg: '/assets/img/boost/energy_drawer.png',
    subImg: '/assets/img/boost/energy.png',
    freeAvailableAmount: 0,
  },
  TURBO: {
    const: 0.1,
    needInvites: 50,
    title: 'Турбо х3',
    subTitle:
      'В 3 раза больше монет за тап при тех же затратах на энергию! Работает 10 минут 🔥',
    mainImg: '/assets/img/boost/rocket_drawer.png',
    freeAvailableAmount: 1,
    subImg: '/assets/img/boost/rocket.png',
  },
  ENERGY_RECOVER: {
    const: 0.1,
    needInvites: 50,
    title: 'Восстановления энергии',
    subTitle: 'Ускоренная зарядка х10',
    mainImg: '/assets/img/boost/speed_drawer.png',
    freeAvailableAmount: 1,
    subImg: '/assets/img/boost/speed.png',
  },
  FULL_ENERGY: {
    const: 0.1,
    needInvites: 50,
    title: 'Восстановления энергии',
    subTitle: 'Полное восстановление полоски энергии',
    mainImg: '/assets/img/boost/speed_drawer.png',
    freeAvailableAmount: 1,
    subImg: '/assets/img/boost/speed.png',
  },
  OPEN_BONK: {
    const: 0.1,
    needInvites: 2,
    title: 'В 2 раза больше монет',
    subTitle: 'за 1 КЛИК!',
    mainImg: '/assets/img/coins/BONK.svg',
    subImg: '/assets/img/boost/speed.png',
    freeAvailableAmount: 0,
  },
  OPEN_WIF: {
    const: 0.1,
    needInvites: 10,
    title: 'В 3 раза больше монет',
    subTitle: 'за 1 КЛИК!',
    mainImg: '/assets/img/coins/WIF.svg',
    subImg: '/assets/img/boost/speed.png',
    freeAvailableAmount: 0,
  },
} as const

export type IBoostType = keyof typeof drawerData

interface IBoostDrawer {
  isOpen: boolean
  isChecked?: boolean
  close: () => void
  handleSwitcher?: () => Promise<void>
  boostType: IBoostType
  gradeTo?: IGradeValueKey
  disabledActiveButton?: boolean
  disabledBuyButton?: boolean
  handleBuy?: () => void
  isLoadingOnBuy?: boolean
  isLoadingOnActive?: boolean
  buttonText?: string
}

export const BoostDrawer = ({
  close,
  handleSwitcher,
  isOpen,
  isChecked = false,
  boostType,
  gradeTo,
  disabledActiveButton = false,
  disabledBuyButton = false,
  handleBuy,
  isLoadingOnBuy = false,
  isLoadingOnActive = false,
  buttonText,
}: IBoostDrawer) => {
  const { handleInvite } = useInvite()
  const referrals = useAppSelector(state => state.userReducer.user!.referrals)

  const handleActive = () => {
    if (disabledActiveButton || isLoadingOnActive) {
      return
    }

    void handleSwitcher?.()
  }

  return (
    <BottomDrawer isOpen={isOpen} onClose={close}>
      <div className={styles.drawer}>
        {gradeTo && (
          <div
            style={{
              backgroundColor: GRADE[gradeTo].color,
              color: GRADE[gradeTo].arrowBg,
            }}
            className={styles.drawer_grade}>
            {GRADE[gradeTo].title} X{GRADE[gradeTo].x}
          </div>
        )}
        {handleSwitcher && (
          <div className={styles.drawer_toggle}>
            <MyToggle checked={isChecked} onChange={handleSwitcher} />
          </div>
        )}
        <img
          className={clsx(styles.drawer_img, {
            [styles.drawer_img_rocket]: boostType === 'TURBO',
          })}
          src={drawerData[boostType].mainImg}
          alt="decoration"
        />
        <div className={styles.drawer_title}>
          <p>{t(drawerData[boostType].title)}</p>
          <span>{t(drawerData[boostType].subTitle)}</span>
        </div>
        {drawerData[boostType].freeAvailableAmount ? (
          <div className={styles.drawer_buttons}>
            <div className={styles.drawer_buttons_item}>
              <p>{t('Доступно 1 раз в день')}</p>
              <div className={styles.drawer_buttons_item_cost}>
                <img src={drawerData[boostType].subImg} alt="boost" />
                <span>{t('Бесплатно')}</span>
              </div>
              <Button
                onClick={handleActive}
                disabled={disabledActiveButton}
                loading={isLoadingOnActive}>
                {buttonText ?? t('Активировать')}
              </Button>
            </div>
            <div className={styles.drawer_buttons_item}>
              <p>{t('Доступно 1 раз в день')}</p>
              <div className={styles.drawer_buttons_item_cost}>
                <img src="/assets/img/coins/TON.svg" alt="TON coin" />
                <span>{drawerData[boostType].const} TON</span>
              </div>
              <Button
                styleType="blue"
                onClick={handleBuy}
                loading={isLoadingOnBuy}
                disabled={disabledBuyButton}>
                {t('Купить')}
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.drawer_buttons}>
            <div className={styles.drawer_buttons_item}>
              <p>{t('Для активации пригласи друзей')}</p>
              <div className={styles.drawer_buttons_item_cost}>
                <img src="/assets/img/boost/friends.svg" alt="friends" />
                <span>
                  {referrals.length}/{drawerData[boostType].needInvites}
                </span>
              </div>
              <Button onClick={handleInvite} styleType="blue">
                {t('Пригласить')}
              </Button>
            </div>
            <div className={styles.drawer_buttons_item}>
              <p>{t('Или приобрети')}</p>
              <div className={styles.drawer_buttons_item_cost}>
                <img src="/assets/img/coins/TON.svg" alt="TON coin" />
                <span>{drawerData[boostType].const} TON</span>
              </div>
              <Button
                styleType="blue"
                onClick={handleBuy}
                disabled={disabledBuyButton}>
                {t('Купить')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </BottomDrawer>
  )
}
