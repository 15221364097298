import styles from './IconWrapper.module.scss'
interface IIconWrapper {
  children: React.ReactNode
}
export const IconWrapper = ({ children }: IIconWrapper) => {
  return (
    <div className={styles.body}>
      <div className={styles.inner}>{children}</div>
    </div>
  )
}
