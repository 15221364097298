import { SectionItem } from '@parts/SectionItem/SectionItem'
import { useToggle } from 'hook/useToggle'
import { useTranslation } from 'react-i18next'
import { BoostDrawer } from '../BoostDrawer'
import { useTapBotActive } from './useTapBotActive'
import { useTapBotBuy } from './useTapBotBuy'

export const TapBot = () => {
  const { t } = useTranslation()

  const [isOpen, toggleIsOpen] = useToggle()

  const { handleTapBot, isLoading } = useTapBotActive()

  const { handleBuy, isLoadingOnBuy, disabledBuyButton } = useTapBotBuy()

  return (
    <>
      <SectionItem
        onClick={toggleIsOpen}
        description={t('100 друзей или 10')}
        icon="/assets/img/boost/auto_tap.png"
        isLoading={isLoading}
        title={'Hot 🔥 Робот автокликер'}
        descriptionIcon="/assets/img/coins/TON.svg"
      />
      <BoostDrawer
        boostType="AUTO_TAP"
        isOpen={isOpen}
        close={toggleIsOpen}
        handleSwitcher={handleTapBot}
        disabledBuyButton={disabledBuyButton}
        isLoadingOnBuy={isLoadingOnBuy}
        handleBuy={async () => await handleBuy(10).then(toggleIsOpen)}
      />
    </>
  )
}
