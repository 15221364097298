import { useEnergyRecoveryBuyMutation } from '@api/endpoints/game'
import { useAppSelector } from '@store/hook'
import { useTransaction } from 'hook/useTransaction'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

export const useEnergyRecoveryBuy = () => {
  const { t } = useTranslation()
  const { handleTransaction } = useTransaction()
  const [mutate, { isLoading }] = useEnergyRecoveryBuyMutation()
  const energy = useAppSelector(state => state.userReducer.user!.energy)
  const maxEnergy = useAppSelector(state => state.userReducer.user!.maxEnergy)

  const handleBuy = async (amount: number) => {
    if (isLoading) {
      return
    }

    if (energy === maxEnergy) {
      toast.error(t('Energy is full'))
      return
    }

    await handleTransaction(amount, async boc => {
      await mutate({
        boc,
        amount,
      })
    })
  }

  return { handleBuy, isLoadingOnBuy: isLoading }
}
