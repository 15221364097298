import { createSlice } from '@reduxjs/toolkit'

interface IUser {
  countryCode: string
  firstName: string
  id: number
  isBlocked: boolean
  language: string
  lastName: string | null
  referralCode: string
  referrerId: number
  username: string
  xp: number
  houses: House[]
  referrals: number[]
  maxEnergy: number
  energy: number
  level: number
  nextFreeEnergyRecoveryBoost: Date | null
  isBuyChargingSpeedBoost: boolean
  chargingSpeedBoost: boolean
  isBuyTapBotBoost: boolean
  tapBotBoost: boolean
  subscribedToChannelDate?: string
  subscribedToChannel?: string
  subscribedToChat?: string
  subscribedToChatDate?: string
  isBuyLimitEnergyBoost: boolean
  limitEnergyBoost: boolean
  refLink: string
  nextFreeTurboBoost: string | null
  turboBoostEndedAt: string
  tapBotStatistics?: {
    PEPE: number
    BONK: number
    WIF: number
    SHIB: number
    DOGE: number
  }
  dailyProfitReferrals?: {
    PEPE: number
    BONK: number
    WIF: number
    SHIB: number
    DOGE: number
  }
  lastOnline: string
  createdAt?: string
  tonAddress?: string
  tonWalletName?: string
  transactionsHistory: TransactionsHistory[]
  totalLogins: number
  loginsToday: number
  claimedEnergyDay: number
  lastTimeClaimedEnergy: string
  additionalEnergy: number
}

export interface TransactionsHistory {
  id: string
  amount: number
  hash: string
  createdAt: string
}

export interface House {
  id: string
  name: string
  type: string
  multiplier: number
  price: number
  currency: string
  userId: number
}

const initialState: { user: IUser | null } = {
  user: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const newData = { ...action.payload }
      delete newData.coins
      delete newData.tapBotStatistics?.id
      delete newData.tapBotStatistics?.userId
      delete newData.dailyProfitReferrals?.id
      delete newData.dailyProfitReferrals?.userId

      state.user = newData
    },
    setHouses: (state, action) => {
      if (state.user) {
        if (action.payload?.maxEnergy) {
          state.user.houses = action.payload.houses
          state.user.maxEnergy = action.payload.maxEnergy
          if (action.payload?.setEnergy) {
            state.user.energy = action.payload.maxEnergy
          }
        } else {
          state.user.houses = action.payload
        }
      }
    },
    logout: state => {
      state.user = null
    },
    addReferral: (state, action) => {
      if (state.user) {
        state.user.referrals.push(action.payload)
      }
    },
    setFullEnergyBoost: (state, action) => {
      if (state.user) {
        state.user.nextFreeEnergyRecoveryBoost =
          action.payload.nextFreeEnergyRecoveryBoost
        state.user.energy = action.payload.energy
      }
    },
    setEnergy: (state, action) => {
      if (state.user) {
        state.user.energy = action.payload
      }
    },
    decrementEnergy: (state, action) => {
      if (state.user) {
        state.user.energy =
          action.payload >= 0 ? action.payload : state.user.energy
      }
    },
    setLevel: (state, action) => {
      if (state.user) {
        state.user.level = action.payload
      }
    },
    setChargingSpeedBoost: (state, action) => {
      const user = state.user

      if (user) {
        user.chargingSpeedBoost = action.payload
        if (!user.isBuyChargingSpeedBoost && action.payload) {
          user.isBuyChargingSpeedBoost = true
        }
      }
    },
    restoreTurboBoost: (state, action) => {
      if (state.user) {
        state.user.nextFreeTurboBoost = action.payload.freeTurboBoost
      }
    },
    restoreEnergyRecoveryBoost: (state, action) => {
      if (state.user) {
        state.user.nextFreeEnergyRecoveryBoost =
          action.payload.nextFreeEnergyRecoveryBoost
      }
    },
    setTapBotBoost: (state, action) => {
      const user = state.user

      if (user) {
        user.tapBotBoost = action.payload
        if (!user.isBuyTapBotBoost && action.payload) {
          user.isBuyTapBotBoost = true
        }
      }
    },
    setLimitEnergyBoost: (state, action) => {
      const user = state.user

      if (user) {
        const upEnergy = user.maxEnergy * 2
        const defaultEnergy = user.maxEnergy / 2

        user.limitEnergyBoost = action.payload
        user.maxEnergy = action.payload ? upEnergy : defaultEnergy
        user.energy =
          !action.payload && user.energy > defaultEnergy
            ? defaultEnergy
            : user.energy
        if (!user.isBuyLimitEnergyBoost && action.payload) {
          user.isBuyLimitEnergyBoost = true
          user.maxEnergy = user.maxEnergy * 2
        }
      }
    },
    changeSubscribedToChannel: (state, action) => {
      if (state.user) {
        state.user.subscribedToChannel = action.payload.subscribedToChannel
        state.user.subscribedToChannelDate =
          action.payload.subscribedToChannelDate
      }
    },
    changeSubscribedToChat: (state, action) => {
      if (state.user) {
        state.user.subscribedToChat = action.payload.subscribedToChat
        state.user.subscribedToChatDate = action.payload.subscribedToChatDate
      }
    },

    setTurboBoost: (state, action) => {
      if (state.user) {
        state.user.nextFreeTurboBoost = new Date(
          action.payload.nextFreeTurboBoost,
        ).toISOString()
        state.user.turboBoostEndedAt = new Date(
          action.payload.turboBoostEndedAt,
        ).toISOString()
      }
    },

    clearBotStats: state => {
      if (state.user) {
        state.user.tapBotStatistics = {
          PEPE: 0,
          BONK: 0,
          WIF: 0,
          SHIB: 0,
          DOGE: 0,
        }
      }
    },

    setBlocked: state => {
      if (state.user) {
        state.user.isBlocked = true
      }
    },

    setWallet: (state, action) => {
      if (state.user) {
        state.user.tonAddress = action.payload.address
        state.user.tonWalletName = action.payload.walletName
      }
    },

    setLastClaimedData: (state, action) => {
      if (state.user) {
        state.user.energy = action.payload.energy
        state.user.maxEnergy = action.payload.maxEnergy
        state.user.lastTimeClaimedEnergy = action.payload.lastTimeClaimedEnergy
        state.user.claimedEnergyDay = action.payload.claimedEnergyDay
        state.user.additionalEnergy = action.payload.additionalEnergy
      }
    },

    purchasedEnergyRecoveryBoost: (state, action) => {
      if (state.user) {
        state.user.energy = action.payload
      }
    },

    purchasedTurboBoost: (state, action) => {
      if (state.user) {
        state.user.turboBoostEndedAt = action.payload
      }
    },

    purchasedTapBot: state => {
      if (state.user) {
        state.user.isBuyTapBotBoost = true
        state.user.tapBotBoost = true
      }
    },

    purchasedLimitEnergy: (state, action) => {
      if (state.user) {
        state.user.isBuyLimitEnergyBoost = action.payload.isBuyLimitEnergyBoost
        state.user.limitEnergyBoost = action.payload.limitEnergyBoost
        state.user.maxEnergy = action.payload.maxEnergy
      }
    },
  },
})

export const { reducer: userReducer, actions: userActions } = userSlice
