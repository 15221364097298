import { useAppSelector } from '@store/hook'
import {
  useTonConnectUI,
  useTonWallet,
  type Locales,
  type SendTransactionRequest,
} from '@tonconnect/ui-react'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useSetWallet } from './useSetWallet'

export const useTransaction = () => {
  const { t } = useTranslation()
  const wallet = useTonWallet()
  const [tonConnectUI, setOptions] = useTonConnectUI()

  const tonAddress = useAppSelector(state => state.userReducer.user!.tonAddress)

  const { handleSetWallet } = useSetWallet()

  const language = useAppSelector(
    state => state.userReducer.user!.language,
  ) as Locales

  useEffect(() => {
    setOptions({ language })
  }, [language])

  const handleTransaction = async (
    amount: number,
    callback: (boc: string) => Promise<void>,
  ) => {
    const transaction: SendTransactionRequest = {
      messages: [
        {
          address: 'UQAGwvQda7TB93utANOCGsVFyCFM7n8B4nmnE13w5uUAdgxb',
          amount: String(amount * 1e9),
        },
      ],
      validUntil: Math.floor(Date.now() / 1000) + 60,
    }

    try {
      if (wallet) {
        if (!tonAddress && wallet) {
          await handleSetWallet(wallet.account.address, wallet.device.appName)
        }

        const { boc } = await tonConnectUI.sendTransaction(transaction)
        console.debug(boc, 'boc')
        await callback(boc)
        toast.success(t('Через некоторое время'))
      } else {
        await tonConnectUI.openModal()
      }
    } catch (error) {
      console.error(`Transaction error`, error)
      const message = error?.data?.message
      if (typeof message === 'string') {
        toast.error(t(message))
        return
      }
      toast.error(t('Транзакция не удалась'))
    }
  }

  return { handleTransaction }
}
