export const CHANNEL_LINKS = {
  ru: 'https://t.me/memeworldton',
  en: 'https://t.me/tonmemeworld',
}

export const CHATS_IDS = {
  ru: 'https://t.me/+GCri4ycqqZA1ODRk',
  en: 'https://t.me/+wROiVPp84mRkYTM0',
}

export const CLAIM_ENERGY = [
  { energy: 100, day: 1 },
  { energy: 100, day: 2 },
  { energy: 200, day: 3 },
  { energy: 300, day: 4 },
  { energy: 400, day: 5 },
  { energy: 500, day: 6 },
  { energy: 600, day: 7 },
  { energy: 700, day: 8 },
  { energy: 800, day: 9 },
  { energy: 900, day: 10 },
  { energy: 1000, day: 11 },
  { energy: 2000, day: 12 },
  { energy: 3000, day: 13 },
  { energy: 4000, day: 14 },
  { energy: 5000, day: 15 },
]
