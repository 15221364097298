import { getItemFromLocalStorage } from '@utils/localStorageService'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './locales/en.json'
import fr from './locales/fr.json'
import de from './locales/de.json'
import es from './locales/es.json'
import ru from './locales/ru.json'
import tr from './locales/tr.json'
// import hi from './locales/hi.json'
// import ua from './locales/ua.json'

const getDefaultLanguage = () => {
  const lang = getItemFromLocalStorage('lang') ?? 'en'

  document.documentElement.lang = lang
  return lang
}

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
  ru: {
    translation: ru,
  },
  tr: {
    translation: tr,
  },
  // ua: {
  //   translation: ua,
  // },
  // hi: {
  //   translation: hi,
  // },
}

void i18n.use(initReactI18next).init({
  fallbackLng: getDefaultLanguage(),
  debug: false,
  resources,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
