import { useBuyTapBotBoostMutation } from '@api/endpoints/game'
import { useAppSelector } from '@store/hook'
import { useTransaction } from 'hook/useTransaction'

export const useTapBotBuy = () => {
  const { handleTransaction } = useTransaction()
  const [mutate, { isLoading }] = useBuyTapBotBoostMutation()
  const isBuyTapBotBoost = useAppSelector(
    state => state.userReducer.user!.isBuyTapBotBoost,
  )

  const handleBuy = async (amount: number) => {
    if (isLoading) {
      return
    }

    await handleTransaction(amount, async boc => {
      await mutate({
        boc,
        amount,
      })
    })
  }

  return {
    handleBuy,
    isLoadingOnBuy: isLoading,
    disabledBuyButton: isBuyTapBotBoost,
  }
}
