import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import styles from './PageTitle.module.scss'

interface IPageTitle {
  img?: string
  title?: string
  subTitle?: string
  className?: string
  imgSize?: { width: number; height: number }
  Element?: JSX.Element
}

export const PageTitle = ({
  img,
  title,
  subTitle,
  className,
  imgSize = { width: 157, height: 94 },
  Element,
}: IPageTitle) => {
  const { t } = useTranslation()

  return (
    <div className={clsx(styles.title, className)}>
      {img && (
        <img
          width={imgSize.width}
          height={imgSize.height}
          src={img}
          alt="decoration"
        />
      )}
      {title && <h2>{t(title)}</h2>}
      {subTitle && <p>{t(subTitle)}</p>}
      {Element && Element}
    </div>
  )
}
