import { Bubble } from './Bubble'
import styles from './BubbleContainer.module.scss'
import { useAppSelector } from '@store/hook'
// import { getRandomInt } from '@utils/getRandomInt'

// const bubbles: IBubble[] = [
//   {
//     icon: '/assets/img/coins/PEPE.svg',
//     x: 1,
//     color: 'green',
//     title: 'PEPE',
//   },

//   {
//     icon: '/assets/img/coins/BONK.svg',
//     x: 5,
//     color: 'blue',
//     title: 'BONK',
//   },
//   {
//     icon: '/assets/img/coins/SHIB.svg',
//     x: 10,
//     color: 'purple',
//     title: 'SHIB',
//   },
//   {
//     icon: '/assets/img/coins/DOGE.svg',
//     x: 25,
//     color: 'yellow',
//     title: 'DOGE',
//   },
//   {
//     icon: '/assets/img/coins/WIF.svg',
//     x: 50,
//     color: 'bright_green',
//     title: 'WIF',
//   },
// ]

// const FAKE_BUBBLES: IBubble[] = []

// for (let i = 0; i < 10; i++) {
//   const newItem = { ...bubbles[getRandomInt(0, bubbles.length - 1)] }
//   FAKE_BUBBLES.push(newItem)
// }

export const BubbleContainer = () => {
  const bubbles = useAppSelector(state => state.bubblesReducer.bubblesItems)

  return (
    <div className={styles.body}>
      {bubbles.map(data => (
        <Bubble {...data} key={data.coinIndex} />
      ))}
    </div>
  )
}

// setTimeout(() => {
//   dispatch(bubblesActions.removeBubbles(data.index))
// }, 3000)
// return (
//   <React.Fragment key={data.index}>
//     {data.bubbles.map(bubble => (
//       <Bubble {...bubble} key={data.index + bubble.title} />
//     ))}
//   </React.Fragment>
// )
