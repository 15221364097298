import clsx from 'clsx'
import styles from './Loader.module.scss'

export const Loader = ({ center = false }: { center?: boolean }) => {
  return (
    <div
      className={clsx(styles.loader, {
        [styles.center]: center,
      })}
    />
  )
}
