import { COIN, type ICoinKey } from '@enums/coin'
import { useAppSelector } from '@store/hook'
import clsx from 'clsx'
import { useToggle } from 'hook/useToggle'
import { useState } from 'react'
import styles from './components.module.scss'

export const CoinsCounter = () => {
  const [selected, setSelected] = useState<ICoinKey>(COIN.PEPE)
  const [isOpen, toggleIsOpen] = useToggle()
  const coins = useAppSelector(state => state.bubblesReducer.coins)
  return (
    <div
      className={clsx(styles.item_wrapper, styles.coins, {
        [styles.coins_active]: isOpen,
      })}>
      <div onClick={toggleIsOpen} className={styles.coins_selected}>
        <img
          className={styles.coins_icon}
          src={`/assets/img/coins/${selected}.svg`}
          alt={selected}
        />
        <span className={clsx(styles.text, styles.coins_text)}>
          {/* // @ts-expect-error fix */}
          {coins[selected]}
        </span>
        <img
          className={styles.coins_icon_arrow}
          src="/assets/img/main/round_arrow_down.svg"
          alt="arrow open/close"
        />
      </div>

      <div className={styles.coins_options}>
        {Object.values(COIN).map(coin => {
          return coin !== selected ? (
            <div
              key={coin}
              onClick={() => {
                toggleIsOpen()
                setSelected(coin)
              }}
              className={clsx(styles.coins_options_coin)}>
              <img
                className={styles.coins_icon}
                src={`/assets/img/coins/${coin}.svg`}
                alt={coin}
              />
              <span className={clsx(styles.text, styles.coins_text)}>
                {coins[coin]}
              </span>
            </div>
          ) : null
        })}
      </div>
    </div>
  )
}
