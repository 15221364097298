import { Loader } from '@parts/Loader/Loader'
import { createPortal } from 'react-dom'

export const FullScreenLoader = () => {
  return createPortal(
    <div
      style={{
        position: 'fixed',
        inset: 0,
        display: 'grid',
        placeItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'var(--bg)',
        zIndex: 1301,
      }}>
      <Loader />
    </div>,
    document.body,
  )
}
