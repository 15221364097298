import { useSubscriptionToChannelMutation } from '@api/endpoints/game'
import { BottomDrawer } from '@parts/BottomDraver'
import { Button } from '@parts/Button'
import { RequestLoader } from '@parts/Loader/RequestLoader'
import { useAppSelector } from '@store/hook'
import { CHANNEL_LINKS } from '@utils/constants'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import styles from './SubscribedToChannel.module.scss'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const DrawerSubscribedToChannel = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation()

  const language = useAppSelector(state => state.userReducer.user!.language)

  const [subscriptionToChannel, { isLoading }] =
    useSubscriptionToChannelMutation()

  const redirectToChannel = () => {
    const url = language === 'ru' ? CHANNEL_LINKS.ru : CHANNEL_LINKS.en
    Telegram.WebApp.openTelegramLink(url)
  }

  const handleSubscribe = async () => {
    if (isLoading) {
      return
    }

    try {
      await subscriptionToChannel()
        .unwrap()
        .then(() => {
          toast.success(t('Вы подписаны на канал'))
        })
    } catch (error) {
      redirectToChannel()
    }
  }

  const handleClose = () => {
    if (isLoading) {
      return
    }

    onClose()
  }

  return (
    <BottomDrawer isOpen={isOpen} onClose={handleClose}>
      <div className={styles.wrapper}>
        <img
          width={147}
          height={109}
          src="/assets/img/settings/subscribe-warning.png"
          alt="warning"
        />
        <div className={styles.text}>
          <p>{t('Внимание!')}</p>
          <span>
            {t(
              'За выход из канала будет приминено наказание в двойном размере от награды',
            )}
          </span>
        </div>
        <div className={styles.buttons}>
          <RequestLoader isLoading={isLoading} />
          <Button onClick={handleSubscribe}>{t('ПЕРЕЙТИ')}</Button>
          <Button onClick={handleClose}>{t('ОТМЕНИТЬ')}</Button>
        </div>
      </div>
    </BottomDrawer>
  )
}
