import { useLimitEnergyBuyBoostMutation } from '@api/endpoints/game'
import { useAppSelector } from '@store/hook'
import { useTransaction } from 'hook/useTransaction'

export const useLimitEnergyBuy = () => {
  const { handleTransaction } = useTransaction()
  const [mutate, { isLoading }] = useLimitEnergyBuyBoostMutation()
  const isBuyLimitEnergyBoost = useAppSelector(
    state => state.userReducer.user!.isBuyLimitEnergyBoost,
  )
  const limitEnergyBoost = useAppSelector(
    state => state.userReducer.user!.limitEnergyBoost,
  )

  const handleBuy = async (amount: number) => {
    if (isLoading) {
      return
    }

    await handleTransaction(amount, async boc => {
      await mutate({
        boc,
        amount,
      })
    })
  }

  return {
    handleBuy,
    isLoadingOnBuy: isLoading,
    disabledBuyButton: isBuyLimitEnergyBoost,
    isChecked: limitEnergyBoost,
  }
}
