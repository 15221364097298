import { useLeadersQuery } from '@api/endpoints/game'
import { BorderWrapper } from '@parts/BorderWrapper'
import { Button } from '@parts/Button'
import { Loader } from '@parts/Loader/Loader'
import { PageTitle } from '@parts/PageTitle'
import { useAppSelector } from '@store/hook'
import { formatNumbers } from '@utils/formatNumbers'
import { useInvite } from 'hook/useInvite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Leaders.module.scss'

export const Leaders = () => {
  const { t } = useTranslation()
  const [tab, setTab] = useState(1)
  const { data, isFetching, isError, isSuccess } = useLeadersQuery(Boolean(tab))
  const id = useAppSelector(state => state.userReducer.user!.id)
  const { handleInvite, handleCopyRefLink } = useInvite()

  const formatFriendsCount = (friends: number) => {
    if (friends === 0) {
      return t('друзей')
    } else if (friends === 1) {
      return t('друг')
    } else if (friends >= 2 && friends <= 4) {
      return t('друга')
    } else {
      return t('друзей')
    }
  }

  return (
    <div className={styles.leaders}>
      <PageTitle
        img={'/assets/img/leaders/leaders_bg.png'}
        title={'Leaders'}
        subTitle={'Improve to get more'}
      />
      <div className={styles.leaders_item_wrapper}>
        <div className={styles.leaders_item_buttons}>
          <Button
            styleType={tab === 1 ? 'yellow' : 'border'}
            onClick={() => {
              if (tab !== 1) {
                setTab(1)
              }
            }}>
            {t('Leaders Friends')}
          </Button>
          <Button
            styleType={tab === 0 ? 'yellow' : 'border'}
            onClick={() => {
              if (tab !== 0) {
                setTab(0)
              }
            }}>
            {t('Top Leaders')}
          </Button>
        </div>
        {isFetching ? (
          <Loader center />
        ) : (
          <>
            {isSuccess && data && data?.length > 0 && (
              <>
                <BorderWrapper style={{ height: 'fit-content' }}>
                  {isError && <p>{t('Ошибка получения данных')}</p>}
                  {data.map((leader, index) => {
                    const position = index + 1
                    const friends = leader.referrals.length
                    return (
                      <div key={position} className={styles.leaders_item}>
                        <div className={styles.leaders_item_position}>
                          {position > 3 ? (
                            <span>{position}</span>
                          ) : (
                            <img
                              width={18}
                              height={24}
                              src={`/assets/img/leaders/position_${position}.png`}
                              alt="leader position"
                            />
                          )}
                        </div>
                        <div className={styles.leaders_item_info}>
                          <img
                            src="/assets/img/leaders/default_avatar.png"
                            width={44}
                            height={44}
                            alt="avatar"
                          />
                          <div className={styles.leaders_item_info_text}>
                            <p>
                              {leader.firstName} {leader.lastName}
                            </p>
                            <div
                              className={styles.leaders_item_info_text_stats}>
                              {friends} {formatFriendsCount(friends)}
                            </div>
                          </div>
                        </div>
                        <div className={styles.leaders_item_is_you}>
                          {+leader.id === id && t('YOU')}
                        </div>
                        <p
                          className={
                            styles.leaders_item_info_text_stats_wrapper
                          }>
                          {Object.entries(leader.coins).map(
                            (coin, index, arr) => {
                              // const value = coin.reverse()
                              // if (arr.length - 1 !== index) {
                              //   value.push(' / ')
                              // }
                              return (
                                <div
                                  key={index}
                                  className={
                                    styles.leaders_item_info_text_stats_wrapper_item
                                  }>
                                  <img
                                    src={`/assets/img/coins/${coin[0]}_TON_BG.svg`}
                                    alt={coin[0]}
                                  />
                                  {formatNumbers(coin[1])}
                                </div>
                              )
                            },
                          )}
                        </p>
                      </div>
                    )
                  })}
                </BorderWrapper>
                <div className={styles.leaders_item_button_invite}>
                  <Button onClick={handleInvite}>
                    {t('ПРИГЛАСИТЬ ДРУГА')}
                  </Button>
                  <Button className={styles.copy} onClick={handleCopyRefLink}>
                    <img
                      width={24}
                      height={24}
                      src="/assets/img/common_icons/copy.svg"
                      alt="copy"
                    />
                  </Button>
                </div>
              </>
            )}
            {isSuccess && data && data?.length === 0 && (
              <BorderWrapper classNameInner={styles.leaders_friends_wrapper}>
                <div className={styles.leaders_friends}>
                  <img
                    width={168}
                    height={120}
                    src="/assets/img/leaders/egg.png"
                    alt="Invite"
                  />
                  <p>{t('Наши друзья - ЗОЛОТО!')}</p>
                  <span>
                    {t('У вас нет активных друзей, вы упускаете прибыль')}
                  </span>
                  <span className={styles.egg}>
                    {t(
                      'Приглашайте друзей и получайте 10% от всех их заработанных монет. Каждый день!',
                    )}
                  </span>
                </div>
                <div className={styles.leaders_item_button_invite}>
                  <Button onClick={handleInvite}>
                    {t('ПРИГЛАСИТЬ ДРУГА')}
                  </Button>
                  <Button className={styles.copy} onClick={handleCopyRefLink}>
                    <img
                      width={24}
                      height={24}
                      src="/assets/img/common_icons/copy.svg"
                      alt="copy"
                    />
                  </Button>
                </div>
              </BorderWrapper>
            )}
          </>
        )}
      </div>
    </div>
  )
}
