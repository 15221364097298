import { useBuyHouseByTonMutation } from '@api/endpoints/game'
import { useAppSelector } from '@store/hook'
import { useTransaction } from './useTransaction'

export const useBuyHouseByTon = () => {
  const { handleTransaction } = useTransaction()
  const houses = useAppSelector(state => state.userReducer.user!.houses)

  const [mutate, { isLoading }] = useBuyHouseByTonMutation()

  const isHasHouse = (house: string) => {
    return houses.some(h => h.name === house)
  }

  const handleBuy = async (
    amount: number,
    house: string,
    type: string = 'COMMON',
    isUpgrade: boolean = false,
  ) => {
    if (isLoading) {
      return
    }

    if (!isUpgrade && isHasHouse(house)) {
      return
    }

    await handleTransaction(amount, async boc => {
      await mutate({
        boc,
        amount,
        house,
        type,
      })
    })
  }

  return { handleBuy, isLoadingOnBuy: isLoading, isHasHouse }
}
