import { SocketContext } from '@providers/SocketProvider'
import { useContext } from 'react'

export const useSocketContext = () => {
  const context = useContext(SocketContext)

  if (!context) {
    throw new Error('SocketContext not found')
  }

  return context
}
