import { PageTitle } from '@parts/PageTitle'
import { OpenBonk } from './OpenBonk'
import { OpenDogWif } from './OpenDogWif'
import styles from './Settings.module.scss'
import { SubscribeToTwitter } from './SubscribeToTwitter'
import { SubscribedToChat } from './SubscribedToChat'
import { Tasks } from './Tasks'
import { Schedule } from './schedule/Schedule'
import { SubscribedToChannel } from './subscribed-to-channel/SubscribedToChannel'

export const Settings = () => {
  return (
    <div className={styles.settings}>
      <PageTitle
        img={'/assets/img/settings/invite_friends.png'}
        title={'Приглашайте друзей и получайте 10% от их заработанных монет,'}
        subTitle={'а также разблокируйте новые токены.'}
        imgSize={{ width: 226.86, height: 186.81 }}
      />
      <div className={styles.sections}>
        <div className={styles.section_items}>
          <Schedule />
          <Tasks />
          <SubscribedToChannel />
          <SubscribedToChat />
          <SubscribeToTwitter />
          <OpenBonk />
          <OpenDogWif />
          {/* <InviteBonus /> */}
          {/* <ReferralsProfit /> */}
        </div>
      </div>
    </div>
  )
}
