import { type RootState } from '@store/hook'
import { bubblesActions } from '@store/slice/bubbles'
import { userActions } from '@store/slice/user'
import { api } from '..'

interface Response<T> {
  code: number
  message: string
  result: T
}

interface Leader {
  id: string
  firstName: string
  lastName: string
  coins: {
    PEPE: number
    BONK: number
    WIF: number
    SHIB: number
    DOGE: number
  }
  referrals: Array<{ id: number }>
  referrerId: string | null
}

interface ProfitFromReferrals {
  PEPE: number
  BONK: number
  WIF: number
  SHIB: number
  DOGE: number
}

const gameApi = api.injectEndpoints({
  endpoints: builder => ({
    toggleLevel: builder.mutation<any, number>({
      query: (level: number) => ({
        url: '/toggle-level',
        method: 'PUT',
        body: {
          level,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled

          const { level, ...rest } = data.result

          dispatch(userActions.setLevel(level))
          dispatch(userActions.setHouses({ ...rest, setEnergy: true }))
        } catch (error) {
          console.log('Toggle level error onQueryStarted', error)
        }
      },
    }),
    upgradeHouse: builder.mutation<any, string>({
      query: house => ({
        url: '/upgrade-house',
        method: 'PUT',
        body: {
          house,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const { coins, ...rest } = data.result
          dispatch(userActions.setHouses(rest))
          dispatch(bubblesActions.setCoins(coins))
        } catch (error) {
          console.log('Toggle level error onQueryStarted', error)
        }
      },
    }),
    fullRecovery: builder.mutation<any, void>({
      query: () => ({
        url: '/boost/energy-recovery',
        method: 'PUT',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(userActions.setFullEnergyBoost(data.result))
        } catch (error) {
          console.log('Full energy boost error onQueryStarted', error)
        }
      },
    }),
    chargingSpeed: builder.mutation<void, void>({
      query: () => ({
        url: '/boost/charging-speed',
        method: 'PUT',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        const isBuyChargingSpeedBoost =
          (getState() as RootState).userReducer.user?.isBuyChargingSpeedBoost ??
          false
        const chargingSpeedBoost =
          (getState() as RootState).userReducer.user?.chargingSpeedBoost ??
          false
        const clicks = (getState() as RootState).bubblesReducer.clicks
        const sendedClicks = (getState() as RootState).bubblesReducer
          .sendedClicks
        const pepeCoins = (getState() as RootState).bubblesReducer.coins.PEPE
        const newValue = !chargingSpeedBoost
        dispatch(userActions.setChargingSpeedBoost(newValue))
        if (!isBuyChargingSpeedBoost && newValue) {
          dispatch(
            bubblesActions.setCoinAmount({
              coin: 'PEPE',
              // amount: pepeCoins - 100_000,
              amount: pepeCoins - 1,
            }),
          )
        }
        if (sendedClicks !== clicks) {
          dispatch(bubblesActions.setSendedClicks(clicks))
        }
        try {
          await queryFulfilled
        } catch (error) {
          dispatch(userActions.setChargingSpeedBoost(chargingSpeedBoost))
          if (!isBuyChargingSpeedBoost) {
            dispatch(
              bubblesActions.setCoinAmount({
                coin: 'PEPE',
                amount: pepeCoins,
              }),
            )
          }
          if (sendedClicks) {
            dispatch(bubblesActions.setSendedClicks(sendedClicks))
          }
          console.log('Charging speed boost error onQueryStarted', error)
        }
      },
    }),
    tapBot: builder.mutation<void, void>({
      query: () => ({
        url: '/boost/tap-bot',
        method: 'PUT',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled
          const tapBotBoost = (getState() as RootState).userReducer.user!
            .tapBotBoost
          const newValue = !tapBotBoost
          dispatch(userActions.setTapBotBoost(newValue))
        } catch (error) {
          console.log('Tap bot boost error onQueryStarted', error)
        }
      },
    }),
    leaders: builder.query<Leader[], boolean>({
      query: (friends?: boolean) => ({
        url: '/leaders',
        method: 'GET',
        params: { friends },
      }),
      transformResponse: (response: Response<Leader[]>) => response.result,
    }),
    subscriptionToChannel: builder.mutation<{ data: any }, void>({
      query: () => ({
        url: '/promo/subscribe-to-channel',
        method: 'PUT',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(userActions.changeSubscribedToChannel(data.data))
        } catch (error) {
          console.log('Subscription to channel error onQueryStarted', error)
        }
      },
    }),
    turbo: builder.mutation<any, void>({
      query: () => ({
        url: '/boost/turbo',
        method: 'PUT',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(userActions.setTurboBoost(data.result))
        } catch (error) {
          console.log('Full energy boost error onQueryStarted', error)
        }
      },
    }),
    limitEnergy: builder.mutation<void, void>({
      query: () => ({
        url: '/boost/limit-energy',
        method: 'PUT',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled
          const limitEnergyBoost = (getState() as RootState).userReducer.user!
            .limitEnergyBoost
          dispatch(userActions.setLimitEnergyBoost(!limitEnergyBoost))
        } catch (error) {
          console.log('Tap bot boost error onQueryStarted', error)
        }
      },
    }),
    getProfitFromReferrals: builder.query<ProfitFromReferrals, void>({
      query: () => '/profit-from-referrals',
      transformResponse: (response: Response<ProfitFromReferrals>) =>
        response.result,
    }),
    setWallet: builder.mutation<void, { address: string; walletName: string }>({
      query: body => ({
        url: '/set-wallet',
        method: 'PUT',
        body,
      }),
      async onQueryStarted(
        { address, walletName },
        { dispatch, queryFulfilled },
      ) {
        try {
          await queryFulfilled
          dispatch(userActions.setWallet({ address, walletName }))
        } catch (error) {
          console.log('Set wallet error onQueryStarted', error)
        }
      },
    }),
    claimEnergy: builder.mutation<{ result: any }, void>({
      query: () => ({
        url: '/claim-energy',
        method: 'PUT',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(userActions.setLastClaimedData(data.result))
        } catch (error) {
          console.log('Claim energy error onQueryStarted', error)
        }
      },
    }),
    subscriptionToChat: builder.mutation<{ data: any }, void>({
      query: () => ({
        url: '/promo/subscribe-to-chat',
        method: 'PUT',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(userActions.changeSubscribedToChat(data.data))
        } catch (error) {
          console.log('Subscription to chat error onQueryStarted', error)
        }
      },
    }),
    turboBuy: builder.mutation<void, { boc: string; amount: number }>({
      query: body => ({
        url: '/boost/turbo/buy',
        method: 'PUT',
        body,
      }),
    }),
    energyRecoveryBuy: builder.mutation<void, { boc: string; amount: number }>({
      query: body => ({
        url: '/boost/energy-recovery/buy',
        method: 'PUT',
        body,
      }),
    }),
    buyHouseByTon: builder.mutation({
      query: body => ({
        url: '/buy-house',
        method: 'PUT',
        body,
      }),
    }),
    buyTapBotBoost: builder.mutation<void, { boc: string; amount: number }>({
      query: body => ({
        url: '/boost/tap-bot/buy',
        method: 'PUT',
        body,
      }),
    }),
    limitEnergyBuyBoost: builder.mutation<
      void,
      { boc: string; amount: number }
    >({
      query: body => ({
        url: '/boost/limit-energy/buy',
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const {
  useToggleLevelMutation,
  useUpgradeHouseMutation,
  useFullRecoveryMutation,
  useChargingSpeedMutation,
  useTapBotMutation,
  useLeadersQuery,
  useSubscriptionToChannelMutation,
  useTurboMutation,
  useLimitEnergyMutation,
  useGetProfitFromReferralsQuery,
  useSetWalletMutation,
  useClaimEnergyMutation,
  useSubscriptionToChatMutation,
  useTurboBuyMutation,
  useEnergyRecoveryBuyMutation,
  useBuyHouseByTonMutation,
  useBuyTapBotBoostMutation,
  useLimitEnergyBuyBoostMutation,
} = gameApi
