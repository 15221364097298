import { SectionItem } from '@parts/SectionItem/SectionItem'
import { useAppSelector } from '@store/hook'
import { useToggle } from 'hook/useToggle'
import { useTranslation } from 'react-i18next'
import { DrawerSubscribedToChannel } from './DrawerSubscribedToChannel'

export const SubscribedToChannel = () => {
  const { t } = useTranslation()
  const [isOpen, toggle] = useToggle()

  const subscribedToChannelDate = useAppSelector(
    state => state.userReducer.user!.subscribedToChannelDate,
  )

  return (
    <>
      <SectionItem
        onClick={subscribedToChannelDate ? () => {} : toggle}
        description={t('Безлим. фарм для подписчиков')}
        icon="/assets/img/common_icons/telegram.png"
        isCompleted={!!subscribedToChannelDate}
        title={t('Подпишись на канал')}
        positionInList="other"
      />
      <DrawerSubscribedToChannel isOpen={isOpen} onClose={toggle} />
    </>
  )
}
