import { ROUTES } from '@utils/routes'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import styles from './components.module.scss'

const links = [
  {
    icon: '/assets/img/main/star.svg',
    href: ROUTES.SETTINGS,
  },
  {
    icon: '/assets/img/main/rocket.svg',
    href: ROUTES.BOOST,
  },
  {
    icon: '/assets/img/main/cup.svg',
    href: ROUTES.LEADERS,
  },
  {
    icon: '/assets/img/main/money.svg',
    href: ROUTES.PRE_MARKET,
  },
]

export const PagesNavigation = () => {
  return (
    <nav className={clsx(styles.item_wrapper, styles.navigation)}>
      {links.map(link => (
        <Link key={link.href} className={styles.navigation_link} to={link.href}>
          <img src={link.icon} alt="navigation link" />
        </Link>
      ))}
    </nav>
  )
}
