import Toggle from 'react-toggle'
import 'react-toggle/style.css'

interface Props {
  checked: boolean
  onChange: () => void
}

export const MyToggle = ({ checked, onChange }: Props) => {
  return <Toggle checked={checked} icons={false} onChange={onChange} />
}
