import { api } from '@api/index'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { bubblesReducer } from './slice/bubbles'
import { layoutReducer } from './slice/layoutSlice'
import { userReducer } from './slice/user'

const rootReducer = combineReducers({
  layoutReducer,
  bubblesReducer,
  userReducer,
  [api.reducerPath]: api.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(api.middleware),
})
