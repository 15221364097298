import { BorderWrapper, type IBorderWrapper } from '@parts/BorderWrapper'
import { IconWrapper } from '@parts/IconWrapper'
import { Loader } from '@parts/Loader/Loader'
import { useTranslation } from 'react-i18next'
import styles from './SectionItem.module.scss'

interface Props {
  isCompleted?: boolean
  isLoading?: boolean
  title?: string
  description?: string
  actionAdditionalIcon?: string
  icon: string
  onClick?: () => void
  positionInList?: IBorderWrapper['positionInList']
  descriptionIcon?: string
}

export const SectionItem = ({
  isCompleted = false,
  isLoading = false,
  title,
  description,
  icon,
  onClick,
  positionInList,
  descriptionIcon,
  actionAdditionalIcon,
}: Props) => {
  const { t } = useTranslation()

  return (
    <BorderWrapper
      style={{
        position: 'relative',
        cursor: isCompleted ? 'default' : 'pointer',
      }}
      positionInList={positionInList}
      onClick={onClick}>
      {isLoading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      <div className={styles.item}>
        <div className={styles.item_body}>
          <IconWrapper>
            <img className={styles.item_body_icon} src={icon} alt={title} />
          </IconWrapper>
          <div className={styles.item_body_info}>
            {title && (
              <p
                style={{
                  color: 'rgb(107, 106, 145)',
                }}>
                {t(title)}
              </p>
            )}
            <div className={styles.item_body_info_text}>
              <p>
                {description}{' '}
                {descriptionIcon && (
                  <img
                    width={16}
                    height={16}
                    src={descriptionIcon}
                    alt={description}
                  />
                )}
              </p>
            </div>
          </div>
          <div className={styles.item_body_action}>
            {actionAdditionalIcon && (
              <img
                className={styles.item_body_action_additional}
                src={actionAdditionalIcon}
                alt="decoration"
              />
            )}
            {isCompleted ? (
              <img
                src="/assets/img/common_icons/tick_circle.svg"
                alt="action"
              />
            ) : (
              <img
                src="/assets/img/common_icons/right_arrow_purple.svg"
                alt="action"
              />
            )}
          </div>
        </div>
      </div>
    </BorderWrapper>
  )
}
