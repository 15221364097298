import { useClaimEnergyMutation } from '@api/endpoints/game'
import { Button } from '@parts/Button'
import { useAppSelector } from '@store/hook'
import { CLAIM_ENERGY } from '@utils/constants'
import clsx from 'clsx'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import styles from './Schedule.module.scss'

const now = new Date()

export const ScheduleDrawerInner = () => {
  const { t } = useTranslation()

  const claimedEnergyDay = useAppSelector(
    state => state.userReducer.user!.claimedEnergyDay,
  )
  const lastTimeClaimedEnergy = useAppSelector(
    state => state.userReducer.user!.lastTimeClaimedEnergy,
  )
  const lastClaimDate = new Date(lastTimeClaimedEnergy)

  const [mutation, { isLoading }] = useClaimEnergyMutation()

  const disabled = now.getDate() === lastClaimDate.getDate()

  const handleClaimEnergy = async () => {
    if (disabled) {
      return
    }

    if (isLoading) {
      return
    }

    try {
      await mutation()
        .unwrap()
        .then(() => {
          toast.success(t('Энергия получена'))
        })
    } catch (error) {
      console.error(`Claim energy error: ${error}`)
      toast.error(t('Не удалось получить энергию'))
    }
  }

  return (
    <div className={styles.body}>
      <img
        className={styles.body_img}
        width={157}
        height={117}
        src="/assets/img/schedule/schedule.png"
        alt="decoration"
      />
      <div className={styles.body_title}>
        <p>{t('Ежедневная Энергия')}</p>
        <span>
          {t(
            'Забирай бесплатную энергию каждый день, но если пропустишь 1 день, все сгорит',
          )}
        </span>
      </div>
      <div className={styles.body_schedule}>
        {CLAIM_ENERGY.map(({ day, energy }) => {
          const isClaimed = day <= claimedEnergyDay
          const toTake =
            claimedEnergyDay + 1 === day &&
            lastClaimDate.getTime() < now.getTime() &&
            now.getDate() !== lastClaimDate.getDate()

          return (
            <div
              key={day}
              className={clsx(styles.body_schedule_item, {
                [styles.body_schedule_item_to_select]: toTake,
              })}>
              <p>
                {t('День')} {day}
              </p>
              <img
                src={
                  isClaimed
                    ? '/assets/img/schedule/tick_square.svg'
                    : '/assets/img/schedule/energy.svg'
                }
                alt="decoration"
              />
              <span>+{energy}</span>
            </div>
          )
        })}
      </div>
      <div className={styles.body_btn}>
        <Button
          onClick={handleClaimEnergy}
          disabled={disabled}
          loading={isLoading}>
          {t('ПОЛУЧИТЬ')}
        </Button>
      </div>
    </div>
  )
}
