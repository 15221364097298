import { Loader } from '@parts/Loader/Loader'
import clsx from 'clsx'
import { useRef, type ButtonHTMLAttributes } from 'react'
import styles from './Button.module.scss'

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleType?: 'border' | 'yellow' | 'blue' | 'grey'
  className?: string
  children: React.ReactNode
  loading?: boolean
}

export const Button = ({
  styleType = 'blue',
  className = '',
  children,
  loading = false,
  ...props
}: IButton) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  return (
    <button
      ref={buttonRef}
      type="button"
      {...props}
      className={clsx(className, styles.button, styles[styleType], {
        [styles.button_loading]: loading,
      })}>
      {styleType === 'border' ? (
        <div className={styles.border_inner}>{children}</div>
      ) : (
        children
      )}
      {loading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
    </button>
  )
}
