import { GRADE_VALUE } from './gradeValue'

export const GRADE = {
  [GRADE_VALUE.COMMON]: {
    color: '#41FF99',
    x: 1,
    arrowBg: '#0E6637',
    title: 'COMMON',
  },
  [GRADE_VALUE.RARE]: {
    color: '#00A3FF',
    x: 5,
    arrowBg: '#0A5681',
    title: 'RARE',
  },
  [GRADE_VALUE.LEGENDARY]: {
    color: '#AC41FF',
    x: 10,
    arrowBg: '#440F6E',
    title: 'LEGENDARY',
  },
  [GRADE_VALUE.IMMORTAL]: {
    color: '#FFE600',
    x: 25,
    arrowBg: '#817500',
    title: 'IMMORTAL',
  },
  [GRADE_VALUE.ARCANA]: {
    color: '#8FFF00',
    x: 50,
    arrowBg: '#3C6902',
    title: 'ARCANA',
  },
} as const

export type IGradeKey = keyof typeof GRADE

export const GRADES_INDEX = {
  [GRADE_VALUE.COMMON]: 1,
  [GRADE_VALUE.RARE]: 2,
  [GRADE_VALUE.LEGENDARY]: 3,
  [GRADE_VALUE.IMMORTAL]: 4,
  [GRADE_VALUE.ARCANA]: 5,
}
