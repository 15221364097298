import { COIN, type ICoinKey } from '@enums/coin'
import { type IBubble } from '@pages/main/components/BubbleContainer/Bubble'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface IBubbleItem extends IBubble {
  coinIndex: string
  type: string
  speed: number
}

interface IInitialState {
  coins: {
    [COIN.PEPE]: number
    [COIN.BONK]: number
    [COIN.WIF]: number
    [COIN.SHIB]: number
    [COIN.DOGE]: number
    [COIN.TON]: number
  }
  clicks: number
  sendedClicks: number
  bubblesItems: IBubbleItem[]
  lastClickedTime: number | null
}

const initialState: IInitialState = {
  coins: {
    [COIN.PEPE]: 0,
    [COIN.BONK]: 0,
    [COIN.WIF]: 0,
    [COIN.SHIB]: 0,
    [COIN.DOGE]: 0,
    [COIN.TON]: 0,
  },
  clicks: 0,
  sendedClicks: 0,
  bubblesItems: [],
  lastClickedTime: null,
}

const bubblesSlice = createSlice({
  name: 'bubble',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.coins[COIN.PEPE] = action.payload[COIN.PEPE]
      state.coins[COIN.BONK] = action.payload[COIN.BONK]
      state.coins[COIN.WIF] = action.payload[COIN.WIF]
      state.coins[COIN.SHIB] = action.payload[COIN.SHIB]
      state.coins[COIN.DOGE] = action.payload[COIN.DOGE]
      state.coins[COIN.TON] = +action.payload[COIN.TON]
    },

    incrementClick: state => {
      state.clicks += 1
    },

    setSendedClicks: (state, action) => {
      state.sendedClicks = action.payload
    },

    incrementCoinAmount: (
      state,
      { payload }: PayloadAction<{ coin: ICoinKey; multiplier: number }>,
    ) => {
      state.coins[payload.coin] = state.coins[payload.coin] + payload.multiplier
    },

    addBubbles: (state, { payload }: PayloadAction<IBubbleItem[]>) => {
      state.bubblesItems = [...state.bubblesItems, ...payload]
    },

    removeBubbles: (state, { payload }: PayloadAction<string>) => {
      state.bubblesItems = state.bubblesItems.filter(
        bubble => bubble.coinIndex !== payload,
      )
    },

    setCoins: (state, action) => {
      state.coins[COIN.PEPE] = action.payload[COIN.PEPE]
      state.coins[COIN.BONK] = action.payload[COIN.BONK]
      state.coins[COIN.WIF] = action.payload[COIN.WIF]
      state.coins[COIN.SHIB] = action.payload[COIN.SHIB]
      state.coins[COIN.DOGE] = action.payload[COIN.DOGE]
      state.coins[COIN.TON] = +action.payload[COIN.TON]
    },

    setCoinAmount: (
      state,
      action: PayloadAction<{ coin: ICoinKey; amount: number }>,
    ) => {
      state.coins[action.payload.coin] = action.payload.amount
    },

    setLastClickedTime: state => {
      state.lastClickedTime = new Date().getTime()
    },
  },
})

export const { actions: bubblesActions, reducer: bubblesReducer } = bubblesSlice
