export const COIN = {
  PEPE: 'PEPE',
  BONK: 'BONK',
  WIF: 'WIF',
  SHIB: 'SHIB',
  DOGE: 'DOGE',
  TON: 'TON',
} as const

export type ICoinKey = keyof typeof COIN
