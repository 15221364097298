import clsx from 'clsx'
import styles from './BorderWrapper.module.scss'
export interface IBorderWrapper {
  children: React.ReactNode
  style?: React.CSSProperties
  innerStyle?: React.CSSProperties
  positionInList?: 'first' | 'last' | 'other'
  onClick?: () => void
  className?: string
  classNameInner?: string
}

export const calcPosition = (i: number, length: number) =>
  i === 0 ? 'first' : i === length - 1 ? 'last' : 'other'

export const BorderWrapper = ({
  children,
  style,
  innerStyle,
  positionInList,
  onClick,
  className,
  classNameInner,
}: IBorderWrapper) => {
  return (
    <div
      className={clsx(styles.body, className, {
        [styles.first]: positionInList === 'first',
        [styles.last]: positionInList === 'last',
        [styles.other]: positionInList === 'other',
      })}
      style={style}
      onClick={onClick}>
      <div className={clsx(styles.inner, classNameInner)} style={innerStyle}>
        {children}
      </div>
    </div>
  )
}
