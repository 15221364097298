import { GRADE, GRADES_INDEX, type IGradeKey } from '@enums/grade'
import { useAppSelector } from '@store/hook'
import { capitalize } from '@utils/capitalize'
import { isAvailableToBuyHouse } from '@utils/isAvailableToBuyHouse'
import { PRICES } from '@utils/prices'
import clsx from 'clsx'
import { useClickAwayListener } from 'hook/useClickAwayListener'
import { useToggle } from 'hook/useToggle'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CardGrades } from './CardGrades'
import { type ICardData } from './Footer'
import styles from './components.module.scss'

const options = Object.entries(GRADE).reverse()

const getOptions = (value: IGradeKey, isBoughtHouse: boolean) => {
  const currentGrade = isBoughtHouse
    ? GRADES_INDEX[value as keyof typeof GRADES_INDEX]
    : 0

  const newList = [...options].reverse().splice(currentGrade).reverse()

  const newValue = newList[newList.length - 1]

  return newValue === undefined ? [] : [newValue]
}

export const Card = ({ icon, coin, value }: ICardData) => {
  const { t } = useTranslation()
  const [openGrades, toggleOpenGrades, setOpenGrades] = useToggle()
  const gradesRef = useRef(null)
  const houses = useAppSelector(state => state.userReducer.user?.houses) ?? []
  const level = useAppSelector(state => state.userReducer.user!.level)

  const handelClickAwayGrades = useCallback(() => {
    setOpenGrades(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useClickAwayListener(gradesRef, handelClickAwayGrades, 'portal')

  const isBoughtHouse = houses.some(house => house.name === coin)
  const allGrades = getOptions(value, isBoughtHouse)
  const isAvailableUpgrade = allGrades[0] && isBoughtHouse
  const needInvite = PRICES[coin][value].needInvite
  const isAvailableToBuy = isAvailableToBuyHouse(coin, level)

  const openDrawer = () => {
    if (allGrades.length > 0) {
      toggleOpenGrades()
    }
  }

  return (
    <>
      <div
        style={{
          borderColor: isBoughtHouse
            ? GRADE[value].color
            : 'rgba(255, 255, 255, 0.06)',
        }}
        className={clsx(styles.card, {
          [styles.card_bought]: isBoughtHouse,
          [styles.card_upgrade]: isAvailableUpgrade,
        })}>
        {isAvailableUpgrade && (
          <div
            style={{
              border: `1px solid ${GRADE[value].color}`,
              backgroundColor: GRADE[value].color,
              color: GRADE[value].arrowBg,
            }}
            className={styles.upgrade}>
            {t('UPGRADE')}
          </div>
        )}
        {!isBoughtHouse && (needInvite || isAvailableToBuy) && (
          <div
            style={{
              border: `1px solid ${GRADE[value].color}`,
              backgroundColor: GRADE[value].color,
              color: GRADE[value].arrowBg,
            }}
            className={styles.upgrade}>
            {t('UPGRADE')}
          </div>
        )}
        <div onClick={openDrawer} className={styles.card_body}>
          <img
            src={`/assets/img/coins/${
              isBoughtHouse ? coin : coin + '_disable'
            }.svg`}
            alt={coin}
          />
          <p>{capitalize(coin)} TMW</p>
          <span style={{ color: GRADE[value].color }}>
            {isBoughtHouse ? (
              <>
                {value} <br />
                {GRADE[value].x}X
              </>
            ) : (
              <p>ADD</p>
            )}
          </span>
        </div>
        <div className={styles.card_decoration} onClick={openDrawer}>
          {isBoughtHouse ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <circle cx="12" cy="12" r="12" fill={GRADE[value].color} />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.95363 12.9921C7.13334 13.2017 7.44899 13.226 7.65866 13.0463L11.9999 9.32523L16.3412 13.0463C16.5509 13.226 16.8665 13.2017 17.0462 12.9921C17.2259 12.7824 17.2017 12.4668 16.992 12.2871L12.3253 8.28706C12.1381 8.12656 11.8618 8.12656 11.6745 8.28706L7.00787 12.2871C6.7982 12.4668 6.77392 12.7824 6.95363 12.9921Z"
                fill={GRADE[value].arrowBg}
              />
              <path
                d="M7.33328 15.8334C7.12405 15.8334 6.93694 15.7031 6.86434 15.5068C6.79175 15.3106 6.84902 15.0899 7.00789 14.9537L11.6746 10.9537C11.8618 10.7932 12.1381 10.7932 12.3253 10.9537L16.992 14.9537C17.1509 15.0899 17.2082 15.3106 17.1356 15.5068C17.063 15.7031 16.8759 15.8334 16.6666 15.8334H7.33328Z"
                fill={GRADE[value].arrowBg}
              />
            </svg>
          ) : needInvite || isAvailableToBuy ? (
            <img src="/assets/img/common_icons/buy.svg" alt="" />
          ) : (
            <img src="/assets/img/common_icons/lock.svg" alt="" />
          )}
        </div>
        {(needInvite || isBoughtHouse || isAvailableToBuy) && openGrades && (
          <div ref={gradesRef} className={styles.grade_options}>
            {allGrades?.map(option => {
              return (
                <CardGrades
                  key={option[0]}
                  value={option[0] as IGradeKey}
                  icon={icon}
                  coin={coin}
                  isAvailableUpgrade={
                    needInvite || isBoughtHouse || isAvailableToBuy
                  }
                  onClose={toggleOpenGrades}
                />
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}
