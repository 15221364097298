import { SocketProvider } from '@providers/SocketProvider'
import { TonConnectProvider } from '@providers/TonConnectProvider'
import RoutesWrapper from 'RoutesWrapper'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { store } from './store'
import './styles/global.scss'

if (Telegram) {
  Telegram.WebApp.expand()
  Telegram.WebApp.onEvent('viewportChanged', () => {
    Telegram.WebApp.expand()
  })
}

let rootElement = document.getElementById('root')

if (!rootElement) {
  rootElement = document.body
}

const root = ReactDOM.createRoot(rootElement)

root.render(
  <React.StrictMode>
    <TonConnectProvider>
      <Provider store={store}>
        <SocketProvider>
          <BrowserRouter>
            <Toaster />
            <RoutesWrapper />
          </BrowserRouter>
        </SocketProvider>
      </Provider>
    </TonConnectProvider>
  </React.StrictMode>,
)

reportWebVitals()
